import React from 'react'
import { styled, Typography, Slider, Paper, Stack, Box, Icon } from '@mui/material'

import { CENTER } from '../../utils/styles';
import { isMobile } from 'react-device-detect';

const PSlider = styled(Slider)(({ theme, ...props }) => ({
    color: '#000',
    height: 2,
    '& .MuiSlider-thumb': {
        width: '13px',
        height: '13px',
        display: 'block',
    },
    '&:hover': {
        cursor: 'auto',
    }
}))

export const Player = () => {
    const player: any = React.useRef()
    const progressBar: any = React.useRef()

    const [isPlaying, setIsPlaying] = React.useState(false);
    const [elapsed, setElapsed] = React.useState(0)
    const [duration, setDuration] = React.useState(0)
    const [volume, setVolume] = React.useState(30)
    const [mute, setMute] = React.useState(false)

    // React.useEffect(() => {
    //     if (isPlaying) {
    //         setInterval(() => {
    //             const duration = Math.floor(player?.current?.duration)
    //             const elapsedTime = Math.floor(player?.current?.currentTime)

    //             setDuration(duration)
    //             setElapsed(elapsedTime)

    //             if (elapsedTime === duration) {
    //                 setIsPlaying(false)
    //                 player.current.stop()
    //             }
    //         }, 100);
    //     }

    //     if (player) {
    //         player.current.volume = volume / 100
    //     }

    // }, [
    //     player?.current?.loadedmetadata,
    //     player?.current?.readyState,
    //     isPlaying, volume
    // ])

    // const calculateTime = (value: any) => {
    //     const minutes = Math.floor(value / 60) < 10 ? `0${Math.floor(value / 60)}` : Math.floor(value / 60)
    //     const seconds = Math.floor(value % 60) < 10 ? `0${Math.floor(value % 60)}` : Math.floor(value % 60)
    //     return `${minutes}:${seconds}`
    // }

    // const togglePausePlay = () => {
    //     const prevVal = isPlaying;
    //     setIsPlaying(!prevVal)

    //     if (!prevVal) {
    //         player.current.play()
    //     } else {
    //         player.current.pause()
    //     }
    // }
    // const handleForward = () => {
    //     player.current.currentTime += 10
    // }

    // const handleReverse = () => {
    //     player.current.currentTime -= 10
    // }

    const togglePrev = () => {
        // if (index > 0) {
        //     setIndex(prev => prev - 1)
        //     player.current.src = require('../../assets/music-example.mp4')
        //     player.current.play()
        // }
    }

    const toggleNext = () => {
        //player.current.stop()
        // if (index >= songs.length - 1) {
        //     setIndex(0)
        //     player.current.src = songs[0]
        //     player.current.play()
        // } else {
        //     setIndex(prev => prev + 1)
        //     player.current.src = songs[index + 1]
        //     player.current.play()
        // }
    }

    return (
        <div style={{ width: '100%', ...CENTER, backgroundColor: 'rgba(185, 185, 185, 0.20)' }}>
            <Box component="div" sx={{ width: isMobile ? '90%' : '80%', marginY: 4, position: 'relative', display: 'flex', justifyContent: 'flex-end' }} >
                {/* <img
                    alt="song"
                    src="https://i1.sndcdn.com/artworks-ZtDaVbDCZdDgxWDH-o8zIVg-t500x500.jpg"
                    style={{ width: '20%', borderRadius: 15, aspectRatio: 1, position: 'absolute', top: '-30px', bottom: '-30px', left: '5%' }}
                />
                <Stack direction={'column'} width={'70%'}>
                    <Typography variant="h6" color="#000">Night Changes</Typography>
                    <Typography variant="body2" color="#000">Produce by One Direction</Typography>
                    <br />
                    <PSlider value={elapsed} max={duration} ref={progressBar} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <audio src={require('../../assets/music-example.mp3')} ref={player} muted={mute} />
                        <Stack direction={"row"} alignItems={"flex-start"} justifyContent={'space-between'} width={'100%'}>
                            <Typography variant="body2" sx={{ color: '#000' }}>{calculateTime(elapsed)}</Typography>
                            <Stack direction='row' gap={3} alignItems={'center'}>
                                <Icon fontSize={'medium'} sx={{ color: '#000', '&:hover': { color: '#545454' } }} onClick={handleReverse}>fast_rewind</Icon>
                                {!isPlaying ?
                                    <Icon
                                        sx={{ color: '#000', '&:hover': { color: '#545454' } }}
                                        fontSize={'large'}
                                        onClick={togglePausePlay}
                                    >play_circle</Icon>
                                    :
                                    <Icon
                                        sx={{ color: '#000', '&:hover': { color: '#545454' } }}
                                        fontSize={'large'}
                                        onClick={togglePausePlay}
                                    >pause_circle</Icon>
                                }
                                <Icon fontSize={'medium'} sx={{ color: '#000', '&:hover': { color: '#545454' } }} onClick={handleForward}>fast_forward</Icon>
                            </Stack>
                            <Typography variant="body2" sx={{ color: '#000' }}>{calculateTime(duration - elapsed)}</Typography>
                        </Stack>
                    </Box>
                </Stack> */}
                <iframe
                    style={{ borderRadius: "12px", width: '100%', height: '250px' }}
                    src="https://open.spotify.com/embed/track/2dhJM2skHC9D41gHj34Kgw?utm_source=generator&theme=0"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                ></iframe>
            </Box>
        </div>
    )
}
