import { Icon, Stack, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { Images } from "../../assets/images";
import { CENTER } from "../../utils/styles";
import { ContactData } from "./data";
import { Link } from "react-router-dom";

export const Content = () => {
  return (
    <div style={{ width: "100%", ...CENTER }}>
      <Stack
        width={isMobile ? "90%" : "80%"}
        direction={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        gap={5}
      >
        <Stack direction={"column"} gap={3} width={isMobile ? "100%" : "30%"}>
          <Typography variant="h4" fontWeight={"600"} color="#000">
            Send Us a Message
          </Typography>
          <Typography variant="body1" color="#000">
            We cut through the clutter to uncover new opportunities, and always
            ensure customer satisfaction
          </Typography>
          <Stack direction={"column"} gap={1}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Icon sx={{ color: "#00c409" }} fontSize={"medium"}>
                check_circle
              </Icon>
              <Typography variant="body1" color="#000">
                <i>Seamless, comprehensive, and cost-effective</i>
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Icon sx={{ color: "#00c409" }} fontSize={"medium"}>
                check_circle
              </Icon>
              <Typography variant="body1" color="#000">
                <i>Connect with your customers</i>
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Icon sx={{ color: "#00c409" }} fontSize={"medium"}>
                check_circle
              </Icon>
              <Typography variant="body1" color="#000">
                <i>Streamline your operations</i>
              </Typography>
            </Stack>
          </Stack>
          <Link
            to="https://api.whatsapp.com/send/?phone=6282249684362&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <img
              src={Images.buttonContact}
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
              alt=""
            />
          </Link>
        </Stack>
        <Stack direction={"column"} gap={3} width={isMobile ? "100%" : "50%"}>
          {ContactData.map((item, index) => (
            <Stack
              direction="row"
              key={index}
              gap={2}
              alignItems={"flex-start"}
              width={"100%"}
            >
              <div
                style={{
                  ...CENTER,
                  width: isMobile ? "15%" : "10%",
                  aspectRatio: 1,
                  borderRadius: isMobile ? 10 : 15,
                  backgroundColor: item.color,
                  boxShadow: "2px 2px 0px 0px rgba(0,0,0,1)",
                  border: "1px solid #000",
                }}
              >
                <Icon sx={{ color: "#000" }} fontSize={"large"}>
                  {item.icon}
                </Icon>
              </div>
              <Stack
                direction={"column"}
                gap={1}
                width={isMobile ? "85%" : "90%"}
              >
                <a href={item.url} target="_blank">
                  <Typography variant="h6" fontWeight={"600"} color="#000">
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#000"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {item.desc}
                  </Typography>
                </a>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </div>
  );
};
