import { Stack, Toolbar } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Header } from '../../components/about/header';
import { Descriptions } from '../../components/about/descriptions';
import { Missions } from '../../components/about/missions';
import { Services } from '../../components/about/services';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';

const AboutUsPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={4} />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Header />
                <Descriptions />
                <Toolbar />
                <Missions />
                <Services />
                <Testimonials />
                <SloganCard />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(AboutUsPage);