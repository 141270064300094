import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { CENTER, TextGradient } from '../../../utils/styles';
import { socialFeatures } from '../data';

export const Features = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={'column'} gap={3} alignItems={'center'} width={isMobile ? '90%' : '80%'}>
                <Paper elevation={3} sx={{ ...CENTER, backgroundColor: '#f0f0f0', borderRadius: 3, width: '100%', position: 'relative' }}>
                    <Paper elevation={3} sx={{ ...CENTER, paddingY: 1, backgroundColor: '#cdd4ea', borderRadius: 9, width: isMobile ? '80%' : '30%', position: 'absolute', top: isMobile ? '-3%' : '-10%' }}>
                        <Typography variant="h5" fontWeight={'600'} sx={{ ...TextGradient }}>Our Features</Typography>
                    </Paper>
                    <div style={{ padding: 20 }}>
                        <Grid container spacing={isMobile ? 2 : 3} marginTop={3}>
                            {socialFeatures.map((item, index) => (
                                <Grid item md={4} sm={6} xs={isTablet && isMobile ? 6 : 12} key={index}>
                                    <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: item.color, borderRadius: 3, width: '100%', height: '100%' }}>
                                        <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color={item.color === '#19191A' ? '#fff' : "#000"}>{item.text}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Paper>
            </Stack>
        </div >
    )
}