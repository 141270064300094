import { Avatar, Stack, Typography, IconButton, Icon } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Icons } from '../../assets/icons'
import { CENTER } from '../../utils/styles'

export const Playlist = () => {
    return (
        <div style={{ width: '100%', ...CENTER, zIndex: 1 }}>
            <Stack direction={isMobile ? "column" : "row"} width={isMobile ? "90%" : "80%"} gap={isMobile ? 5 : 2}>
                <Stack direction={"column"} gap={1} width={isMobile ? "100%" : "50%"}>
                    <Stack direction={"row"} alignItems="center" justifyContent={'space-between'} gap={1} width={"100%"}>
                        <Typography variant="h6" fontWeight={'700'} color="#000">Mix By Kelana</Typography>
                        <Typography variant="h6" color="#000">| MRK</Typography>
                    </Stack>
                    <div style={{ width: '100%', height: '70vh' }}>
                        <Stack direction="column" gap={1} sx={{ width: '100%', height: '70vh' }}>
                            <iframe
                                style={{ borderRadius: "12px", width: '100%', height: '100%' }}
                                src="https://open.spotify.com/embed/playlist/37Gg6HgjAdkqMY3MS9dyjL?utm_source=generator&theme=0"
                                frameBorder="0"
                                allowFullScreen
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"
                            ></iframe>
                            {/* {[...Array(10)].map((item, index) => (
                                <div key={index} style={{ width: '100%', borderRadius: 20, backgroundColor: '#f5f5f5' }}>
                                    <Stack direction={"row"} alignItems="center" gap={1} justifyContent={'space-between'} padding={3} width={'100%'}>
                                        <Stack direction={"row"} alignItems="flex-start" gap={2} width={"75%"}>
                                            <Avatar alt={'as'} src={'https://i.pinimg.com/1200x/bc/dc/2c/bcdc2c96e5493ecde0c68ee9b908710c.jpg'} sx={{ width: 80, height: 80 }}></Avatar>
                                            <div>
                                                <Typography variant="h6" color="#000">Alone, Pt. II</Typography>
                                                <Typography variant="body2" color="#000">Mix by Alan Walker</Typography>
                                            </div>
                                        </Stack>
                                        {index === 1 ?
                                            <Stack
                                                direction={"row"}
                                                alignItems="center"
                                                gap={1}
                                                width={"25%"}
                                                justifyContent={'space-between'}
                                            >
                                                <Typography variant="body1" color="#000">2:59</Typography>
                                                <IconButton sx={{ backgroundColor: '#000' }}>
                                                    <Icon fontSize={'medium'} sx={{ color: '#fff' }}>play_arrow</Icon>
                                                </IconButton>
                                            </Stack>
                                            :
                                            <Stack
                                                direction={"row"}
                                                alignItems="center"
                                                gap={1}
                                                width={"25%"}
                                                divider={<div style={{ height: 30, width: 1, backgroundColor: '#000' }}></div>}
                                                justifyContent={'space-between'}
                                            >
                                                <div style={{ width: '33%' }}>
                                                    <img src={Icons.itunes} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                                </div>
                                                <div style={{ width: '33%' }}>
                                                    <img src={Icons.youtube} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                                </div>
                                                <div style={{ width: '33%' }}>
                                                    <img src={Icons.spotify} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                                </div>
                                            </Stack>
                                        }
                                    </Stack>
                                </div>
                            ))} */}
                        </Stack>
                    </div>
                </Stack>
                <Stack direction={"column"} gap={1} width={isMobile ? "100%" : "50%"}>
                    <Stack direction={"row"} alignItems="center" justifyContent={'space-between'} gap={1} width={"100%"}>
                        <Typography variant="h6" fontWeight={'700'} color="#000">Produce By Kelana</Typography>
                        <Typography variant="h6" color="#000">| MRK</Typography>
                    </Stack>
                    <div style={{ width: '100%', height: '70vh', }}>
                        <Stack direction="column" gap={1} sx={{ width: '100%', height: '100%' }}>
                            <iframe
                                style={{ borderRadius: "12px", width: '100%', height: '100%' }}
                                src="https://open.spotify.com/embed/playlist/5psULQtu89lQLQjtxeJ0o0?utm_source=generator&theme=0"
                                frameBorder="0"
                                allowFullScreen
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"
                            ></iframe>
                        </Stack>
                    </div>
                </Stack>
            </Stack>
        </div>
    )
}