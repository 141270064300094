import { Icon, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { CENTER, TextGradient } from '../../utils/styles';

export const Header = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack width={isMobile ? '90%' : '80%'} direction={isMobile ? 'column' : 'row'} alignItems={'center'} justifyContent={'space-between'} gap={isMobile ? 3 : 5}>
                <Stack direction={'column'} gap={1} alignItems={'flex-start'} width={isMobile ? '100%' : '50%'}>
                    <Typography
                        variant={isTablet && isMobile ? "h2" : isMobile ? "h3" : "h1"}
                        fontWeight={'900'}
                        sx={{ ...TextGradient }}
                    >
                        About Us <sup><Icon fontSize="large" sx={{ ...TextGradient, transform: 'rotate(180deg)' }}>error_outline</Icon></sup>
                    </Typography>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>Home</Typography>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>|</Typography>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>About Us</Typography>
                    </Stack>
                </Stack>
                <Typography variant={isMobile ? "body1" : "h6"} fontWeight={'400'} width={isMobile ? '100%' : '50%'} color="#000">
                    We are an ecosystem of creative talent that specializes in key services to provide creative and sales solution for your brand or campaign needs.
                </Typography>
            </Stack>
        </div>
    )
}