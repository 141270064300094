import { Icon, Stack } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Images } from '../../assets/images';
import { CENTER } from '../../utils/styles';
import { Playlist } from '../../components/MRK/playlist';
import { Player } from '../../components/MRK/player';
import { isMobile } from 'react-device-detect';
import './styles.css'

const MRKPortofolioPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={6} />
            {isMobile ? null :
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 0,
                    ...CENTER,
                    backgroundImage: `url(${Images.background})`,
                    backgroundRepeat: 'repeat',
                    width: '100%'
                }}>
                    <img style={{ width: '70%', height: 'auto', objectFit: 'contain', }} src={Images.mrkBg} alt={'bg'}></img>
                </div>
            }
            <Stack direction={'column'} gap={10} width={'100%'}>
                <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
                    {
                        isMobile ?
                            <div style={{
                                ...CENTER,
                                backgroundImage: `url(${Images.background})`,
                                backgroundRepeat: 'repeat',
                                width: '100%',
                                height: '100%'
                            }}>
                                <img style={{ width: '90%', height: 'auto', objectFit: 'contain' }} src={Images.mrkBg} alt={'bg'}></img>
                            </div>
                            : null
                    }
                    <Stack direction={"column"} gap={-1} className="arrow_down" sx={{ ...CENTER }}>
                        <Icon fontSize={'medium'} sx={{ color: '#000', transform: 'rotate(90deg)' }}>arrow_forward_ios</Icon>
                        <Icon fontSize={'medium'} sx={{ color: '#000', transform: 'rotate(90deg)' }}>arrow_forward_ios</Icon>
                        <Icon fontSize={'medium'} sx={{ color: '#000', transform: 'rotate(90deg)' }}>arrow_forward_ios</Icon>
                    </Stack>
                </div>
                <Playlist />
                <Player />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(MRKPortofolioPage);