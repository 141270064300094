import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { CENTER, TextGradient } from '../../utils/styles';

export const Header = (props: { title: string; desc: string }) => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack width={isMobile ? '90%' : '80%'} direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} gap={isMobile ? 3 : 10}>
                <Stack direction={'column'} gap={1} alignItems={'flex-start'}>
                    <Typography
                        variant={isTablet && isMobile ? "h2" : isMobile ? "h3" : "h1"}
                        fontWeight={'900'}
                        sx={{
                            ...TextGradient,
                            paddingBottom: isMobile ? 0 : 2,
                            textWrap: props.title === 'Live Stream' ? 'nowrap' : 'wrap'
                        }}>{props.title}</Typography>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>Services</Typography>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>|</Typography>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>{props.title}</Typography>
                    </Stack>
                </Stack>
                <Typography variant={isMobile ? 'body1' : "h6"} fontWeight={'400'} color="#000" sx={{ textAlign: 'justify' }}>{props.desc}</Typography>
            </Stack>
        </div>
    )
}