import { Paper, Stack, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Images } from '../../assets/images';
import { Colors } from '../../utils/colors';
import { CENTER, TextGradient } from '../../utils/styles';

export const Descriptions = () => {
    return (
        <div style={{ width: '100%', ...CENTER, backgroundColor: '#f0f0f0', borderRadius: 50, position: 'relative' }}>
            <Stack direction={'column'} gap={5} width={isMobile ? "90%" : "80%"}>
                <Stack justifyItems={'space-between'} direction={'row'} width={"100%"} paddingY={10} position={'relative'}>
                    <Stack direction='column' gap={5} width={isMobile ? '100%' : '70%'}>
                        <Typography variant="h5" fontWeight={'600'} sx={{ ...TextGradient }}>WELCOME TO RABIT CREATIVE ECOSYSTEM</Typography>
                        <Typography variant={isMobile ? "h4" : "h2"} fontWeight={'700'} color="#000">We are a digital agency, passionate about building and designing brands</Typography>
                        <Stack direction={isMobile && !isTablet ? 'column' : 'row'} alignItems={'flex-start'} gap={isMobile ? 3 : 5} justifyContent={'space-between'}>
                            <Stack direction={'column'} gap={2} width={isMobile ? '100%' : '33%'}>
                                <Paper elevation={3} sx={{ backgroundColor: '#f0f0f0', borderRadius: 2, ...CENTER, paddingY: 2, width: '100%' }}>
                                    <Typography variant={"body1"} fontWeight={'600'} color={Colors.primary} textAlign={'center'}>Generating Leads</Typography>
                                </Paper>
                                <Typography variant={"body1"} color={'#000'} sx={{ textAlign: 'justify' }}>To collect market insights for strategy and product development.</Typography>
                            </Stack>
                            <Stack direction={'column'} gap={2} width={isMobile ? '100%' : '33%'}>
                                <Paper elevation={3} sx={{ backgroundColor: '#f0f0f0', borderRadius: 2, ...CENTER, paddingY: 2, width: '100%' }}>
                                    <Typography variant={"body1"} fontWeight={'600'} color={Colors.primary} textAlign={'center'}>Target Audience & Customer</Typography>
                                </Paper>
                                <Typography variant={"body1"} color={'#000'} sx={{ textAlign: 'justify' }}>Driving promote offerings, highlight key features, and solve customer problems .</Typography>
                            </Stack>
                            {isTablet && isMobile ? null :
                                <Stack direction={'column'} gap={2} width={isMobile ? '100%' : '33%'}>
                                    <Paper elevation={3} sx={{ backgroundColor: '#f0f0f0', borderRadius: 2, ...CENTER, paddingY: 2, width: '100%' }}>
                                        <Typography variant={"body1"} fontWeight={'600'} color={Colors.primary} textAlign={'center'}>Engaging Content on Social Media</Typography>
                                    </Paper>
                                    <Typography variant={"body1"} color={'#000'} sx={{ textAlign: 'justify' }}>Sales funnels use platform (Instagram, Shopee, Tokopedia, Lazada, Tiktok, etc) to guide potential customers through the stages of awareness, interest, consideration, and conversion.</Typography>
                                </Stack>
                            }
                        </Stack>
                        {isTablet && isMobile ?
                            <Stack direction={'column'} gap={2} width={'100%'}>
                                <Paper elevation={3} sx={{ backgroundColor: '#f0f0f0', borderRadius: 2, ...CENTER, paddingY: 2, width: '100%' }}>
                                    <Typography variant={"body1"} fontWeight={'600'} color={Colors.primary} textAlign={'center'}>Engaging Content on Social Media</Typography>
                                </Paper>
                                <Typography variant={"body1"} color={'#000'}>Sales funnels use platform (Instagram, Shopee, Tokopedia, Lazada, Tiktok, etc) to guide potential customers through the stages of awareness, interest, consideration, and conversion.</Typography>
                            </Stack>
                            : null
                        }
                    </Stack>
                    {/* {isMobile ? null :
                        <img src={Images.aboutVector3D} style={{ width: '30%', height: 'auto', objectFit: 'contain' }} alt="" />
                    } */}
                </Stack>
                <Toolbar />
                <Toolbar />
                {isMobile && !isTablet ? null : <Toolbar />}
                <div style={{ position: 'absolute', bottom: '-20%', width: '100%', left: 0, right: 0, ...CENTER }}>
                    <img
                        src={isMobile ? Images.homeBg1 : Images.aboutVector3D}
                        style={{
                            width: isMobile ? '90%' : '80%',
                            aspectRatio: isTablet && isMobile ? 4 / 3 : 1,
                            objectFit: 'contain',
                        }}
                        alt=""
                    />
                </div>
            </Stack>
        </div>
    )
}