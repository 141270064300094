import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect';
import { Colors } from '../../../utils/colors';
import { CENTER, TextGradient } from '../../../utils/styles'

export const Descriptions = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (e: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={'column'} gap={3} alignItems={'center'} width={isMobile ? "90%" : "60%"}>
                <Typography variant="h3" fontWeight={'700'} sx={{ ...TextGradient }}>How it works?</Typography>
                <br />
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: 20, border: expanded === 'panel1' ? `1px solid ${Colors.primary}` : '0' }}>
                    <AccordionSummary expandIcon={<Icon fontSize={"large"} sx={{ color: '#000' }}>expand_more</Icon>}>
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                            <Box sx={{ paddingX: 2, aspectRatio: 1, borderRadius: 9, border: `1px solid ${Colors.primary}`, ...CENTER }}>
                                <Typography variant={"h6"} color={"#000"}>1</Typography>
                            </Box>
                            <Typography variant={"h6"} fontWeight={'600'} color={'#000'}>What We Do?</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: '100%', padding: '0 5%' }}>
                            <ul>
                                <li><Typography variant={"body1"} color={'#000'}>Mengembangkan ide kampanye dan brief kreatif.</Typography></li>
                                <li><Typography variant={"body1"} color={'#000'}>Mencari dan memilih KOL sesuai dengan target audiens, membuat kerjasama antara KOL dan brand, dan menetapkan jadwal dari pembuatan konten hingga posting berdasarkan brief kreatif yang dibuat.</Typography></li>
                                <li><Typography variant={"body1"} color={'#000'}>Mengelola kontrak kerjasama dengan KOL, seperti pembuatan MoU, pengelolaan invoice, dan dokumen administratif lain yang diperlukan.</Typography></li>
                                <li><Typography variant={"body1"} color={'#000'}>Melacak performa dan insight dari KOL yang terlibat dalam kampanye.</Typography></li>
                                <li><Typography variant={"body1"} color={'#000'}>Menyediakan worksheet untuk update secara on time.</Typography></li>
                                <li><Typography variant={"body1"} color={'#000'}>Menyusun laporan akhir untuk evaluasi dan pengembangan kampanye berikutnya.</Typography></li>
                            </ul>
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/*  */}
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ borderRadius: 20, border: expanded === 'panel2' ? `1px solid ${Colors.primary}` : '0' }}>
                    <AccordionSummary expandIcon={<Icon fontSize={"large"} sx={{ color: '#000' }}>expand_more</Icon>}>
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                            <Box sx={{ paddingX: 2, aspectRatio: 1, borderRadius: 9, border: `1px solid ${Colors.primary}`, ...CENTER }}>
                                <Typography variant={"h6"} color={"#000"}>2</Typography>
                            </Box>
                            <Typography variant={"h6"} fontWeight={'600'} color={'#000'}>Tiktok Affiliate Program</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: '100%', padding: '0 5%' }}>
                            <Typography variant={'body1'}><b>Tiktok Affiliate Program</b> adalah Program untuk menghubungkan atau menjembatani kreator dengan penjual/brand melalui keranjang kuning pada short video dan live streaming dengan sistem sharing komisi.</Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/*  */}
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ borderRadius: 20, border: expanded === 'panel3' ? `1px solid ${Colors.primary}` : '0' }}>
                    <AccordionSummary expandIcon={<Icon fontSize={"large"} sx={{ color: '#000' }}>expand_more</Icon>}>
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                            <Box sx={{ paddingX: 2, aspectRatio: 1, borderRadius: 9, border: `1px solid ${Colors.primary}`, ...CENTER }}>
                                <Typography variant={"h6"} color={"#000"}>3</Typography>
                            </Box>
                            <Typography variant={"h6"} fontWeight={'600'} color={'#000'}>Instagram Endorsement</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: '100%', padding: '0 5%' }}>
                            <Typography variant={'body1'}><b>Instagram Endorsement</b> adalah mempromosikan produk atau layanan melalui posting foto atau video dalam kerjasama dengan kompensasi (ratecard).</Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/*  */}
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ borderRadius: 20, border: expanded === 'panel4' ? `1px solid ${Colors.primary}` : '0' }}>
                    <AccordionSummary expandIcon={<Icon fontSize={"large"} sx={{ color: '#000' }}>expand_more</Icon>}>
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                            <Box sx={{ paddingX: 2, aspectRatio: 1, borderRadius: 9, border: `1px solid ${Colors.primary}`, ...CENTER }}>
                                <Typography variant={"h6"} color={"#000"}>4</Typography>
                            </Box>
                            <Typography variant={"h6"} fontWeight={'600'} color={'#000'}>Mechanism</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: '100%', padding: '0 5%' }}>
                            <Stack direction={'column'} gap={2}>
                                <Stack direction={isMobile ? 'column' : 'row'} justifyContent={"space-between"} gap={2}>
                                    <div style={{ width: isMobile ? '100%' : '50%' }}>
                                        <Typography variant={'body1'}><b>A. Campaign Plan</b></Typography>
                                        <ol>
                                            <li><Typography variant={'body1'}>Objective campaign</Typography></li>
                                            <li><Typography variant={'body1'}>Produk dan SOW</Typography></li>
                                            <li><Typography variant={'body1'}>Menentukan komisi (tiktok)</Typography></li>
                                        </ol>
                                    </div>
                                    <div style={{ width: isMobile ? '100%' : '50%' }}>
                                        <Typography variant={'body1'}><b>B. Listing KOL</b></Typography>
                                        <ol>
                                            <li><Typography variant={'body1'}>Kategori niche KOL</Typography></li>
                                            <li><Typography variant={'body1'}>Kategori Followers (instagram)</Typography></li>
                                            <li><Typography variant={'body1'}>Video / Live stream GPM (Tiktok)</Typography></li>
                                            <li><Typography variant={'body1'}>Kualitas konten (instagram)</Typography></li>
                                            <li><Typography variant={'body1'}>CTR%, CO, AoV (Tiktok)</Typography></li>
                                        </ol>
                                    </div>
                                </Stack>
                                <div style={{ width: '100%' }}>
                                    <Typography variant={'body1'}><b>C. Report</b></Typography>
                                    <ol>
                                        <li><Typography variant={'body1'}>Jumlah detail transaksi dan sales yang dihasilkan affiliator dari campaign. (Tiktok) KOL yang menghasilkan engagement tinggi - support ads dan repeat campaign (Tiktok dan Instagram) Menentukan kreatif konten yang cocok untuk brand. (Tiktok dan Instagram)</Typography></li>
                                        <li><Typography variant={'body1'}>KOL yang menghasilkan engagement tinggi - support ads dan repeat campaign (Tiktok dan Instagram)</Typography></li>
                                        <li><Typography variant={'body1'}>Menentukan kreatif konten yang cocok untuk brand. (Tiktok dan Instagram)</Typography></li>
                                    </ol>
                                </div>
                            </Stack>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </div >
    )
}