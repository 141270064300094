import * as React from 'react';
import { Button, Divider, Icon, Stack, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { useNavigate } from "react-router"
import { Icons } from "../../assets/icons"
import { Images } from "../../assets/images"
import { Colors } from "../../utils/colors"
import { CENTER } from "../../utils/styles"
import { footerNavigate } from "./data"
import FloatBtn from "../floatWA/float-btn"
import { Link } from 'react-router-dom';

export const Footer = () => {
    const navigate = useNavigate()
    const [IconHover, setIconHover] = React.useState(false)

    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={'column'} gap={3} width={isMobile ? '90%' : '80%'}>
                <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 5 : 10} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={isMobile ? '100%' : '35%'}>
                        {/* <img src={Icons.logoText} style={{ height: 'auto', width: '80%', objectFit: 'contain' }} alt="" /> */}
                        <Stack direction={'row'} alignItems={"flex-start"}>
                            <Stack direction={'column'}>
                                <div></div>
                                <img src={Icons.logoText} onClick={() => navigate('/')} style={{ height: 50, width: 'auto', maxWidth: '100%', objectFit: 'contain', cursor: 'pointer' }} alt="" />
                            </Stack>
                            <img
                                onMouseEnter={() => setIconHover(true)}
                                onMouseLeave={() => setIconHover(false)}
                                src={Images.mrkLogo}
                                style={{ 
                                    width: 30, 
                                    height: 'auto', 
                                    objectFit: 'contain', 
                                    cursor: 'pointer',
                                    transform: IconHover ? 'scale(1.5)' : 'scale(1)',
                                    transition: 'all .2s'
                                }}
                                alt=""
                                onClick={() => navigate('/mrk-portofolio')}
                            />
                        </Stack>
                        <br />
                        <Typography variant="body1" color={Colors.primary} fontWeight={'600'}>Stay Inspired with RCE</Typography>
                        <Typography variant="body1" color={'#000'}>Get exclusive access to our latest works, creative trends, and tips delivered straight to your inbox.</Typography>
                        <div style={{ width: '100%', height: 1, backgroundColor: '#ddd' }}></div>
                        <Typography variant="body1" color={'#000'}>Graha Kencana, Komplek No.88 Kec. Kb. Jeruk, Jakarta, Daerah Khusus Ibukota Jakarta 11530</Typography>
                    </Stack>
                    <Stack direction={'column'} gap={1} alignItems={'flex-start'} width={isMobile ? '100%' : '20%'}>
                        <Typography variant="body1" color={Colors.primary} fontWeight={'600'}>Quick Links</Typography>
                        {isMobile ? null : <br />}
                        {footerNavigate.map((item, index) => (
                            <Typography variant="body1" key={index} color={'#000'}>{item.label}</Typography>
                        ))}
                    </Stack>
                    <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={isMobile ? '100%' : '30%'}>
                        <Typography variant="body1" color={Colors.primary} fontWeight={'600'}>{isMobile ? 'Stay Updated!' : 'Subscribe and Stay Updated!'}</Typography>
                        {isMobile ? null :
                            null
                        }
                        {isMobile ?
                        <Link to='https://www.instagram.com/rabitcreative/' target='_blank'>
                            <Button
                                variant={'contained'}
                                color={"warning"}
                                size={'large'}
                                onClick={() => navigate('/contact')}
                                sx={{ backgroundColor: Colors.primary, color: '#000', borderRadius: 30, paddingY: 1, paddingX: 3 }}
                                endIcon={<Icon fontSize={'small'} sx={{ color: '#000' }}>arrow_forward</Icon>}
                            >Follow Us</Button>
                            </Link>
                            :
                            <Link to='https://www.instagram.com/rabitcreative/' target='_blank'>
                            <Button
                                variant={'contained'}
                                color={"warning"}
                                sx={{ backgroundColor: Colors.primary, color: '#000', borderRadius: 30, paddingY: 1, paddingX: 3 }}
                                endIcon={<Icon fontSize={'small'} sx={{ color: '#000' }}>arrow_right</Icon>}
                            >Follow Us</Button></Link>
                        }
                        <br />
                        {/* <Stack direction="row" gap={1} alignItems={'center'} divider={<span>|</span>} justifyContent={isMobile ? 'space-between' : 'flex-start'}>
                            <Typography variant={"body1"} color="#000">Facebook</Typography>
                            <Typography variant={"body1"} color="#000">Instagram</Typography>
                            <Typography variant={"body1"} color="#000">LinkedIn</Typography>
                            <Typography variant={"body1"} color="#000">Twitter</Typography>
                        </Stack> */}
                    </Stack>
                </Stack>
                <Divider />
                <div style={{ width: '100%', ...CENTER }}>
                    <Typography variant="body1" color="#000">{'©️ 2024 Rabit Creative Ecosystem'}</Typography>
                </div>
            </Stack>
            <FloatBtn />
        </div >
    )
}