import { Images } from "../../assets/images";

export const ServicesData = [
  {
    img: Images.homeBg2,
    title: "Live Stream Services",
    desc: "Through our in-house studio with skilled live streaming operators and professional equipment are dedicated to providing you with a professionally executed, high-quality live stream.",
  },
  {
    img: Images.aboutBg1,
    title: "KOL and Influencer Marketing",
    desc: "KOL and Influencer marketing is a strategic method within digital marketing that utilizes individuals' influence to promote a brand, product, or service.",
  },
  {
    img: Images.aboutBg2,
    title: "Social Media Management",
    desc: "We help brands to create content, publishing, developing a strategy, interacting with users, growing reach, and monitoring the performance of your brand’s account on social media.",
  },
  {
    img: Images.aboutBg3,
    title: "Content Creation",
    desc: "we act of producing and sharing information or media content for specific audiences, particularly in digital contexts.",
  },
];
