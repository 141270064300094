import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { CENTER, TextGradient } from '../../utils/styles';

export const Missions = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={"column"} width={isMobile ? '90%' : '80%'} gap={3}>
                <Stack direction={isMobile ? 'column' : 'row'} alignItems={"flex-start"} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h5" fontWeight={'600'} sx={{ ...TextGradient }} width={isMobile ? '100%' : "20%"}>Our Mission</Typography>
                    <Typography variant={isMobile ? 'body1' : "h6"} color={'#000'} width={isMobile ? '100%' : "80%"} sx={{ textAlign: 'justify' }}>From the moment our company was founded, we have helped our clients find exceptional solutions for their businesses , creating memorable brands and digital products. Our expertise grows with each year, and our accumulated experience.</Typography>
                </Stack>
                <Stack direction={isMobile ? 'column' : 'row'} alignItems={"flex-start"} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h5" fontWeight={'600'} sx={{ ...TextGradient }} width={isMobile ? '100%' : "20%"}>Our Goal</Typography>
                    <Typography variant={isMobile ? 'body1' : "h6"} color={'#000'} width={isMobile ? '100%' : "80%"}>Our goal is to deliver amazing experiences that make people talk, and build strategic value for brands, tech, entertainment, arts & culture.</Typography>
                </Stack>
            </Stack >
        </div >
    )
}