import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { CENTER, TextGradient } from '../../utils/styles';

export const Header = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack width={isMobile ? '90%' : '80%'} direction={isMobile ? 'column' : 'row'} alignItems={'flex-start'} justifyContent={'space-between'} gap={isMobile ? 3 : 5}>
                <Stack direction={'column'} gap={1} alignItems={'flex-start'}>
                    <Typography variant={isTablet && isMobile ? 'h2' : isMobile ? 'h3' : "h1"} fontWeight={'900'} sx={{ ...TextGradient }}>PORTFOLIO</Typography>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>Home</Typography>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>|</Typography>
                        <Typography variant="h6" color="#000" fontWeight={'600'}>Portfolio</Typography>
                    </Stack>
                </Stack>
                <Typography sx={{ textAlign: 'justify'}} variant={isMobile ? 'body1' : "h6"} fontWeight={'400'} color="#000">Content Creation! We create over 1000 creative pieces every year. Join us! Just like our clients Kencana Energy, Common Grounds, City Storage, City Stream, BUZZ Indonesia, and many more. Let's transform your brand into a captivating story through our imaginative content! 🚀</Typography>
            </Stack>
        </div>
    )
}