import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Images } from "../../assets/images";
import { CENTER, TextGradient } from "../../utils/styles";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div style={{ width: "100%", ...CENTER, zIndex: 1 }}>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        width={isMobile ? "90%" : "80%"}
        gap={isMobile ? 3 : 0}
      >
        <Stack direction={"column"} width={isMobile ? "100%" : "50%"}>
          <Stack
            direction={"row"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Typography
              variant={isMobile ? "h3" : "h1"}
              fontWeight={"900"}
              color="#000"
              sx={{
                background:
                  "linear-gradient(to right, #060401 0%, #5d3d0a 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Let's
            </Typography>
            {isMobile && !isTablet ? null : (
              <Link
                to="https://api.whatsapp.com/send/?phone=6282249684362&text&type=phone_number&app_absent=0"
                style={{ width: "60%", height: "auto", objectFit: "contain" }}
                target="_blank"
              >
                <img
                  src={Images.buttonContact}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </Link>
            )}
          </Stack>
          <Typography
            variant={isMobile ? "h3" : "h1"}
            fontWeight={"900"}
            sx={{ ...TextGradient }}
          >
            Collaborate
          </Typography>
        </Stack>
        {isMobile && !isTablet ? (
          <Link
            to="https://api.whatsapp.com/send/?phone=6282249684362&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <img
              src={Images.buttonContact}
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
              alt=""
            />
          </Link>
        ) : null}
        <Typography
          variant={isMobile ? "body1" : "h5"}
          color="#000"
          sx={{
            width: isMobile ? "100%" : "45%",
            fontWeight: "400",
            textAlign: "justify",
          }}
        >
          We are passionate about crafting a creative ecosystem where everyone
          supports each other and grows to become better.
        </Typography>
      </Stack>
    </div>
  );
};
