export const Images = {
  mrkLogo: require("./mrk-logo.png"),
  background: require("./background-grid.png"),
  vector3D: require("./3d-vector.png"),
  aboutVector3D: require("./about3d-vector.png"),
  mrkBg: require("./mrk-bg.png"),
  homeBg1: require("./home-bg1.png"),
  homeBg2: require("./home-bg2.png"),
  homeBg3: require("./home-bg3.png"),
  vectorFluid: require("./fluid-vector.png"),
  buttonContact: require("./contact-button.png"),
  slogan: require("./slogan.png"),
  aboutBg1: require("./about-bg2.png"),
  aboutBg2: require("./about-bg3.png"),
  aboutBg3: require("./about-bg4.png"),
  livePackage1: require("./live-stream1.png"),
  livePackage2: require("./live-stream2.png"),
  livePackage3: require("./live-stream3.png"),
  livePackage4: require("./live-stream4.png"),
  kolImg1: require("./kol-image1.png"),
  kolImg2: require("./kol-image2.png"),
  kolImg3: require("./kol-image3.png"),
  kolImg4: require("./kol-image4.png"),
  socialBg: require("./social-bg.png"),
  socialPackage1: require("./social-media1.png"),
  socialPackage2: require("./social-media2.png"),
  socialPackage3: require("./social-media3.png"),
  socialPackage4: require("./social-media4.png"),
  socialGrid1: require("./social-grid1.png"),
  socialGrid2: require("./social-grid2.png"),
  contentImg1: require("./content-image1.png"),
  contentImg2: require("./content-image2.png"),
  contentPackage1: require("./content-creation1.png"),
  contentPackage2: require("./content-creation2.png"),
  contentPackage3: require("./content-creation3.png"),
  contentPackage4: require("./content-creation4.png"),
  rabit1: require("./rabit1.png"),
  rabit2: require("./rabit2.png"),
  liveCopper: require("./live-Copper.png"),
  liveHanum: require("./live-Hanum.png"),
  liveHerbana: require("./live-Herbana.png"),
  liveKojie: require("./live-Kojie.png"),
  liveLSL: require("./live-LSL.png"),
  livePersib: require("./live-Persib.png"),
  livePrecious: require("./live-Precious.png"),
  liveRealfood: require("./live-Realfood.png"),
  liveRealfoodSporte: require("./live-RealfoodSporte.png"),
  portoBuzz: require("./porto-buzz.png"),
  portoCityStorage: require("./porto-city-storage.png"),
  portoCity: require("./porto-city.png"),
  portoBeringin: require("./porto-beringin.png"),
  portoKiloalta: require("./porto-kiloalta.png"),
  portoCommon: require("./porto-common.png"),
  sosmedBirhagia: require("./sosmed-birhagia.png"),
  sosmedOurganica: require("./sosmed-ourganica.png"),
  sosmedJana: require("./sosmed-jana.png"),
  sosmedBeringin: require("./sosmed-beringin.png"),
  sosmedKencana: require("./sosmed-kencana.png"),
  persibLive: require("./persib-live.png"),
  sosmedSosmed: require("./sosmed.png"),
  liveLive: require("./live-live.png"),
  // Precious: require("")
  Precious: require("./precious-baru.jpeg"),
  Lae:require("./Logo-Laesaluay.jpeg"),
  PersibFix: require("./persib-hitam.png"),
};
