import { Stack, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';
import { Header } from '../../components/services/header';
import { Features } from '../../components/services/KOLInfluencer/features';
import { Milestone } from '../../components/services/KOLInfluencer/milestone';
import { Icons } from '../../assets/icons';
import { Colors } from '../../utils/colors';
import { Descriptions } from '../../components/services/KOLInfluencer/descriptions';
import { Marketing } from '../../components/services/KOLInfluencer/marketing';
import { isMobile } from 'react-device-detect';

const ServicesInfluencerPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={3} />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Stack direction={'column'} gap={10} width={'100%'} sx={{ backgroundColor: "#f0f0f0", borderRadius: '0 0 50px 50px', paddingBottom: '5%' }}>
                    <Toolbar />
                    <Header
                        title={isMobile ? 'KOL and Influencer' : 'KOL and Influencer Marketing'}
                        desc='Our client provide testimonials that reflect the positive impact our solutions have had on their through e-commerce have increased fivefold compared to before the TAP campaign.'
                    />
                    <Features />
                </Stack>
                <Milestone />
                <Stack direction={'row'} paddingY={1} alignItems={'center'} justifyContent={"center"} gap={isMobile ? 3 : 5} width={'100%'} sx={{ backgroundColor: Colors.primary }}>
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                </Stack>
                <Descriptions />
                <Marketing />
                <Testimonials />
                <SloganCard />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(ServicesInfluencerPage);