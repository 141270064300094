import { Grid, Icon, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { CENTER } from '../../../utils/styles';
import { marketingList } from '../data';

export const Marketing = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <div style={{ width: isMobile ? '90%' : '80%' }}>
                <Grid container spacing={5}>
                    {
                        marketingList.map((item, index) => (
                            <Grid item md={3} xs={isTablet && isMobile ? 6 : isMobile ? 12 : 3} key={index}>
                                <Stack direction={'column'} gap={3} width={'100%'}>
                                    <img src={item.img} style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }} alt="" />
                                    <Stack direction={"column"} gap={1}>
                                        <Typography variant="body1" color="#000">KOL & iNFLUENCER MARKETING</Typography>
                                        <Typography variant="h4" fontWeight={'700'} color="#000">{item.title}</Typography>
                                        <Icon fontSize={'large'} sx={{ color: '#000' }}>more_horiz</Icon>
                                        <Typography variant="body1" color="#000" textAlign={"justify"}>{item.desc}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </div >
    )
}