export const Icons = {
  asterisk: require("./asterisk-icon.png"),
  birhagia: require("./birhagia-logo.png"),
  buzz: require("./buzz-logo.png"),
  cityStream: require("./city-stream-logo.png"),
  jmw: require("./jmw-logo.png"),
  logoWhite: require("./logo-white.png"),
  logoYellow: require("./logo-yellow.png"),
  logo: require("./logo.png"),
  logoText: require("./logo-text.png"),
  message: require("./message-icon.png"),
  realfood: require("./realfood-logo.png"),
  copper: require("./copper-logo.png"),
  instagram: require("./instagram-icon.png"),
  spotify: require("./spotify-icon.png"),
  youtube: require("./youtube-icon.png"),
  itunes: require("./itunes-icon.png"),


};

export const Clients = [
  require("./client_logo/client-1.png"),
  require("./client_logo/client-2.png"),
  // require("./client_logo/client-3.png"),
  // require("./client_logo/client-4.png"),
  require("./client_logo/client-5.png"),
  require("./client_logo/client-6.png"),
  require("./client_logo/client-7.png"),
  require("./client_logo/client-8.png"),
  // require("./client_logo/client-9.png"),
  require("./client_logo/client-10.png"),
  require("./client_logo/client-11.png"),
  require("./client_logo/client-12.png"),
  require("./client_logo/client-13.png"),
  // require("./client_logo/client-14.png"),
  require("./client_logo/client-15.png"),
  require("./client_logo/client-16.png"),
  require("./client_logo/client-17.png"),
  require("./client_logo/client-18.png"),
  // require("./client_logo/client-19.png"),
  require("./client_logo/client-20.png"),
  require("./client_logo/client-21.png"),
  // require("./client_logo/client-22.png"),
  require("./client_logo/client-23.png"),
  // require("./client_logo/client-24.png"),
  // require("./client_logo/client-25.png"),
  // require("./client_logo/client-26.png"),
  // require("./client_logo/client-27.png"),
  require("./client_logo/client-28.png"),
  require("./client_logo/client-29.png"),
  require("./client_logo/client-30.png"),
  require("./client_logo/Birhagia-Logo.png"),
  require("./client_logo/Mykonos.jpeg"),
  require("./client_logo/persib-hitam.png"),
  require("./client_logo/precious-baru.jpeg"),
  require("./client_logo/Logo-Laesaluay.jpeg"),
];
