import { Icon, IconButton } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useSwiper } from "swiper/react";

export const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return (
        <IconButton sx={{ backgroundColor: '#fff', padding: 0.5 }} onClick={() => swiper.slideNext()}>
            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: '#000' }}>chevron_right</Icon>
        </IconButton>
    )
};

export const SwiperButtonPrev = () => {
    const swiper = useSwiper();
    return (
        <IconButton sx={{ backgroundColor: '#fff', padding: 0.5 }} onClick={() => swiper.slidePrev()}>
            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: '#000' }}>chevron_left</Icon>
        </IconButton>
    )
};