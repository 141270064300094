import { Stack, Typography, Grid, Paper } from "@mui/material";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { Images } from "../../assets/images";
import { CENTER, TextGradient } from "../../utils/styles";
import { ServicesData } from "./data";
import { Link } from "react-router-dom";

export const Services = () => {
  return (
    <div style={{ width: "100%", ...CENTER }}>
      <Stack direction={"column"} gap={5} width={isMobile ? "90%" : "80%"}>
        <Stack direction={"column"}>
          <Typography variant="h5" color="#000" fontWeight={"600"}>
            What we Do
          </Typography>
          <Typography
            variant={isMobile ? "h4" : "h3"}
            sx={{ ...TextGradient }}
            fontWeight={"600"}
          >
            We're a full-service agency
          </Typography>
        </Stack>
        <Grid container spacing={5} alignItems={"stretch"}>
          {ServicesData.map((item, index) => (
            <Grid item md={6} sm={12} key={index}>
              <Paper elevation={3} sx={{ borderRadius: 3, height: "100%" }}>
                <Stack
                  direction={"column"}
                  sx={{ borderRadius: "inherit", padding: isMobile ? 2 : 5 }}
                  gap={3}
                >
                  <img
                    src={item.img}
                    alt=""
                    style={{
                      width: "100%",
                      aspectRatio: 1,
                      objectFit: "cover",
                      borderRadius: "inherit",
                    }}
                  />
                  <Typography variant="h6" color="#000" fontWeight={"600"}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#000"
                    textAlign={"justify"}
                  >
                    {item.desc}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <div style={{ width: "100%", ...CENTER }}>
          <a
            href="https://api.whatsapp.com/send/?phone=6282249684362&text&type=phone_number&app_absent=0"
            target="_blank"
            style={{
              width: isMobile ? "80%" : "30%",
              height: "auto",
              objectFit: "contain",
            }}
          >
            <img
              src={Images.buttonContact}
              style={{ width: "100%", height: "auto" }}
              alt=""
            />
          </a>
        </div>
      </Stack>
    </div>
  );
};
