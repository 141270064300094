import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Images } from '../../../assets/images'
import { CENTER, TextGradient } from '../../../utils/styles'
import { quotaList } from '../data'

export const Quota = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems={'center'} width={isMobile ? '90%' : "70%"} gap={5}>
                <div style={{ width: isMobile ? '100%' : '50%' }}>
                    <img src={Images.homeBg1} style={{ width: '100%', aspectRatio: 4 / 3, objectFit: 'cover', borderRadius: 20 }} alt="" />
                </div>
                <Stack direction={'column'} gap={5} width={isMobile ? '100%' : '50%'}>
                    <Stack direction={isMobile && !isTablet ? 'column' : 'row'} gap={3} width='100%' alignItems={'flex-start'} justifyContent={'space-between'}>
                        <Typography variant="h6" sx={{ ...TextGradient }} fontWeight={'600'}>Quota System</Typography>
                        <Typography variant="body1" width={isTablet && isMobile ? '60%' : isMobile ? '100%' : '50%'} color={'#000'}>The number of points can be converted for various brand needs.</Typography>
                    </Stack>
                    <Grid container spacing={2}>
                        {quotaList.map((item, index) => (
                            <Grid item md={6} sm={6} xs={isTablet && isMobile ? 6 : 12} key={index}>
                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                    <Box sx={{ backgroundColor: item.color, width: isMobile ? '10%' : '15%', aspectRatio: 1, borderRadius: 1 }}></Box>
                                    <Typography variant="body1" color="#000" width={isMobile ? '85%' : "80%"}>{item.text} {index === 5 ? <small>*Please confirm in advance.</small> : null}</Typography>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </Stack>
        </div>
    )
}