export const Hrefs = [
  { id: 1, label: "Home", navigate: "/" },
  { id: 2, label: "Portfolio", navigate: "/portofolio" },
  { id: 3, label: "Services", navigate: "/" },
  { id: 4, label: "About Us", navigate: "/about-us" },
  { id: 5, label: "Contact", navigate: "/contact" },
];

export const Services = [
  { label: "Live Stream", navigate: "/service/live-stream" },
  {
    label: "KOL and Influencer Marketing",
    navigate: "/service/kol-influencer-marketing",
  },
  {
    label: "Social Media Management",
    navigate: "/service/social-media-management",
  },
  {
    label: "Content Creation",
    navigate: "/service/content-creation",
  },
];

export const footerNavigate = [
  { label: "About Us", navigate: "" },
  { label: "Privacy Policy", navigate: "" },
  { label: "Terms & Conditions", navigate: "" },
  { label: "Cookie Policy", navigate: "" },
];
