import { Stack, Toolbar } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';
import { Header } from '../../components/portofolio/header';
import { PortList } from '../../components/portofolio/portList';

const PortofolioPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={2} />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Header />
                <PortList />
                <Testimonials page={'portofolio'} />
                <SloganCard />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(PortofolioPage);