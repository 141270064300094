export const ContactData = [
  {
    icon: "mail_outline",
    color: "#cdd4ea",
    title: "Email",
    desc: "marketing@rabitcreativeecosystem.com",
    url: "mailto:marketing@rabitcreativeecosystem.com",
  },
  {
    icon: "call",
    color: "#ffbf00",
    title: "Phone",
    desc: "++62 822 4968 4362",
    url: "https://api.whatsapp.com/send/?phone=6282249684362&text&type=phone_number&app_absent=0",
  },
  {
    icon: "place",
    color: "#e6e6e6",
    title: "Headquarters",
    desc: "Graha Kencana, Jl. Perjuangan Komplek No.88 blok GK, Kb. Jeruk, Kec. Kb. Jeruk, Jakarta, Daerah Khusus Ibukota Jakarta 11530",
    url: "https://maps.app.goo.gl/Ag6ifeHZ6iPXtdMj9",
  },
];
