import * as React from "react";
import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    Icon,
    CssBaseline,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    ListItemText,
    Button,
    Typography,
    useScrollTrigger,
} from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router";
import { CENTER } from "../../utils/styles";
import { Icons } from "../../assets/icons";
import { Hrefs, Services } from "./data";
import { Colors } from "../../utils/colors";
import { Images } from "../../assets/images";
import { Link } from "react-router-dom";

const NavigationBar = (props: { active: number }) => {
    const navigate = useNavigate();
    const [isDrawer, setDrawer] = React.useState(false);
    const [service, setService] = React.useState<any>(null);
    const [IconHover, setIconHover] = React.useState(false)

    const toggleDrawer = () => {
        setDrawer(!isDrawer)
    };

    const serviceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setService(event.currentTarget);
    };

    const serviceClose = () => {
        setService(null);
    };

    const Navs = (e: any, item: any) => {
        if (item.label === 'Services') {
            serviceClick(e)
        } else {
            setDrawer(false)
            navigate(item.navigate)
        }
    }

    const onScroll = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return (
        <Box sx={{ display: 'flex', width: '100%', position: 'relative' }}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="fixed"
                sx={{
                    backgroundColor: onScroll ? '#ffffff' : 'transparent',
                    width: '100%',
                    top: 0,
                    transition: 'all .3s',
                    zIndex: 5
                }}
            >
                <Toolbar sx={{ ...CENTER }}>
                    {
                        isTablet && isMobile ?
                            <Stack spacing={2} width={'100%'}>
                                <Stack
                                    width={'100%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <Stack direction={'row'}>
                                        <Stack direction={'column'}>
                                            <div></div>
                                            <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 30, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                        </Stack>
                                        <img
                                            onMouseEnter={() => setIconHover(true)}
                                            onMouseLeave={() => setIconHover(false)}
                                            src={Images.mrkLogo}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                objectFit: 'contain',
                                                cursor: 'pointer',
                                                transform: IconHover ? 'scale(1.5)' : 'scale(1)',
                                                transition: 'all .2s'
                                            }}
                                            alt=""
                                            onClick={() => navigate('/mrk-portofolio')}
                                        />
                                    </Stack>
                                    {/* <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 30, width: 'auto', objectFit: 'contain' }} alt="" className="" /> */}
                                    <Stack direction="row" alignItems={'center'} gap={1} >
                                        <IconButton onClick={() => setDrawer(true)}>
                                            <Icon fontSize={'medium'} sx={{ color: '#000' }}>menu</Icon>
                                        </IconButton>
                                        {/* <img
                                            src={Images.mrkLogo}
                                            style={{ width: 30, height: 30, objectFit: 'contain', cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => navigate('/mrk-portofolio')}
                                        /> */}
                                        <Link to='https://www.instagram.com/rabitcreative/' target="_blank">
                                        <Button
                                            variant={'contained'}
                                            color={"warning"}
                                            size={'small'}
                                            endIcon={<img src={Icons.instagram} style={{ width: 15, height: 15, objectFit: 'contain' }} alt="" />}
                                            sx={{ backgroundColor: Colors.primary, color: '#000', fontWeight: '600', borderRadius: 30, width: '80%', paddingRight: 3, paddingLeft: 3 }}
                                        >Instagram</Button></Link>
                                    </Stack>
                                </Stack>
                            </Stack>
                            :
                            isMobile ?
                                <Stack spacing={2} width={'100%'}>
                                    <Stack
                                        width={'100%'}
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        <Stack direction={'row'}>
                                            <Stack direction={'column'}>
                                                <div></div>
                                                <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 30, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                            </Stack>
                                            <img
                                                onMouseEnter={() => setIconHover(true)}
                                                onMouseLeave={() => setIconHover(false)}
                                                src={Images.mrkLogo}
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    objectFit: 'contain',
                                                    cursor: 'pointer',
                                                    transform: IconHover ? 'scale(1.5)' : 'scale(1)',
                                                    transition: 'all .2s'
                                                }}
                                                alt=""
                                                onClick={() => navigate('/mrk-portofolio')}
                                            />
                                        </Stack>
                                        {/* <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 30, width: 'auto', objectFit: 'contain' }} alt="" className="" /> */}
                                        <Stack direction="row" alignItems={'center'} gap={1}>
                                            <IconButton onClick={() => setDrawer(true)}>
                                                <Icon fontSize={'medium'} sx={{ color: '#000' }}>menu</Icon>
                                            </IconButton>
                                            {/* <img
                                                src={Images.mrkLogo}
                                                style={{ width: 30, height: 30, objectFit: 'contain', cursor: 'pointer' }}
                                                alt=""
                                                onClick={() => navigate('/mrk-portofolio')}
                                            /> */}
                                        </Stack>
                                    </Stack>
                                </Stack>
                                :
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Stack
                                        width={isTablet ? '95%' : '85%'}
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        sx={{ padding: isTablet ? '10px 0' : '20px 0' }}
                                    >
                                        {/* <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain', cursor: 'pointer' }} alt="" className="" /> */}
                                        <Stack direction={'row'}>
                                            <Stack direction={'column'}>
                                                <div></div>
                                                <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 50, width: 'auto', objectFit: 'contain', cursor: 'pointer' }} alt="" className="" />
                                            </Stack>
                                            <img
                                                onMouseEnter={() => setIconHover(true)}
                                                onMouseLeave={() => setIconHover(false)}
                                                src={Images.mrkLogo}
                                                style={{ 
                                                    width: 20, 
                                                    height: 20, 
                                                    objectFit: 'contain', 
                                                    cursor: 'pointer',
                                                    transform: IconHover ? 'scale(1.5)' : 'scale(1)',
                                                    transition: 'all .2s'
                                                }}
                                                alt=""
                                                onClick={() => navigate('/mrk-portofolio')}
                                            />
                                        </Stack>
                                        <Stack direction={'row'} gap={isTablet ? 0 : 2} alignItems={'center'} sx={{ color: '#000' }}>
                                            {
                                                Hrefs.map((item, index) => (
                                                    <Button
                                                        key={index}
                                                        variant="text"
                                                        color="inherit"
                                                        onClick={(e) => Navs(e, item)}
                                                        sx={{ textTransform: 'none' }}
                                                        endIcon={item.id === 3 ? <Icon fontSize={'medium'} sx={{ color: '#000' }}>keyboard_arrow_down</Icon> : null}
                                                    >
                                                        <Stack direction='column' gap={0} alignItems={'center'}>
                                                            <Typography variant="body1" fontWeight={props.active === item.id ? '600' : '400'} color="inherit">{item.label}</Typography>
                                                            {props.active === item.id ?
                                                                <div style={{ width: '50%', backgroundColor: Colors.primary, borderRadius: 30, height: 5 }}></div>
                                                                : null
                                                            }
                                                        </Stack>
                                                    </Button>
                                                ))
                                            }
                                        </Stack>
                                        <Stack direction={'row'} gap={2} alignItems={'center'}>
                                            {/* <img
                                                src={Images.mrkLogo}
                                                style={{ width: 40, height: 40, objectFit: 'contain', cursor: 'pointer' }}
                                                alt=""
                                                onClick={() => navigate('/mrk-portofolio')}
                                            /> */}
                                            <Link to='https://www.instagram.com/rabitcreative/' target="_blank">
                                            <Button
                                                variant={'contained'}
                                                color={"warning"}
                                                sx={{ backgroundColor: Colors.primary, color: '#000', fontWeight: '600', borderRadius: 30 }}
                                                endIcon={<img src={Icons.instagram} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />}
                                            >Instagram</Button></Link>
                                        </Stack>
                                    </Stack>
                                </div>
                    }
                </Toolbar>
            </AppBar>
            {
                (isTablet && isMobile) || isMobile ?
                    <Drawer
                        sx={{
                            width: '100%',
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: '100%',
                                boxSizing: "border-box",
                            },
                        }}
                        variant={"temporary"}
                        anchor="right"
                        open={isDrawer}
                        onClose={toggleDrawer}
                    >
                        <Stack direction={'column'} gap={3} padding={2}>
                            <Stack direction={'row'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                <IconButton onClick={() => setDrawer(false)}>
                                    <Icon fontSize={'large'} sx={{ color: '#000' }}>close</Icon>
                                </IconButton>
                            </Stack>
                            <Stack direction={'column'} gap={1} sx={{ color: '#000' }}>
                                {
                                    Hrefs.map((item, index) => (
                                        <Stack key={index} direction={'column'} gap={1}>
                                            <Button variant="text" color="inherit" onClick={(e) => Navs(e, item)}>
                                                <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} paddingLeft={2}>
                                                    <Typography variant={'button'} color="#000">{item.label}</Typography>
                                                </Stack>
                                            </Button>
                                            {
                                                item.label === 'Services' ?
                                                    Services.map((item, index) => (
                                                        <Button variant="text" color="inherit" key={index} onClick={(e) => Navs(e, item)}>
                                                            <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} paddingLeft={5}>
                                                                <Typography variant={'button'} color="#000">{item.label}</Typography>
                                                            </Stack>
                                                        </Button>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Stack>
                                    ))
                                }
                            </Stack>
                        </Stack>
                    </Drawer>
                    :
                    null
            }
            <Menu
                anchorEl={service}
                open={Boolean(service)}
                onClose={serviceClose}
            >
                {
                    Services.map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                setService(null)
                                navigate(item.navigate)
                            }}
                            sx={{
                                "&:hover": {
                                    color: '#000',
                                    transition: "all .3s",
                                    backgroundColor: '#e7e7e7',
                                },
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                transition: "all .3s"
                            }}
                        >
                            <ListItemText sx={{ color: "inherit" }}>{item.label}</ListItemText>
                        </MenuItem>
                    ))
                }
            </Menu>
        </Box>
    );
};

export default NavigationBar;
