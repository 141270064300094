import { Stack, Toolbar } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';
import { Header } from '../../components/services/header';
import { Services } from '../../components/services/liveStream/services';
import { Packages } from '../../components/services/liveStream/packages';
import { Content } from '../../components/services/liveStream/content';

const ServicesLiveStreamPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={3} />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Header
                    title='Live Stream'
                    desc='Through our in-house studio with skilled live streaming operators and professional equipment are dedicated to providing you with a professionally executed, high-quality live stream.'
                />
                <Services />
                <Packages />
                <Content />
                <Testimonials />
                <SloganCard />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(ServicesLiveStreamPage);