import { Button, Grid } from "@mui/material";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Colors } from "../../../utils/colors";
import { CENTER } from "../../../utils/styles";
import { packagesSocial } from "../data";
import { Link } from "react-router-dom";

export const Packages = () => {
  return (
    <div
      style={{
        width: "100%",
        ...CENTER,
        backgroundColor: isMobile ? "#fff" : "#f0f0f0",
        borderRadius: "50px 50px 0 0",
      }}
    >
      <Grid container spacing={2} sx={{ paddingY: isMobile ? 5 : 10 }}>
        {packagesSocial.map((item, index) => (
          <Grid item key={index} md={3} xs={isTablet && isMobile ? 6 : 12}>
            <div
              style={{ width: "100%", height: "auto", position: "relative" }}
            >
              <img
                src={item}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                  position: "relative",
                }}
                alt=""
              />
              <Link
                to="https://api.whatsapp.com/send/?phone=6282249684362&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <Button
                  color={"warning"}
                  sx={{
                    backgroundColor: Colors.primary,
                    position: "absolute",
                    bottom: 0,
                    left: isTablet && isMobile ? "15%" : "25%",
                    right: isTablet && isMobile ? "15%" : "25%",
                    borderRadius: 30,
                  }}
                  variant={"contained"}
                  size={isTablet && isMobile ? "small" : "large"}
                >
                  Order Now
                </Button>
              </Link>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
