import { Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Images } from '../../assets/images';
import { CENTER, TextGradient } from "../../utils/styles"
import { features } from './data';

export const Features = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={'column'} gap={5} width={isMobile ? '90%' : '80%'}>
                <Stack direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={'center'} gap={3} justifyContent={'space-between'}>
                    <Typography
                        variant={isMobile && isTablet ? 'h3' : isMobile ? 'h2' : "h2"}
                        fontWeight={'500'}
                        textAlign={isMobile ? 'center' : 'left'}
                        sx={{
                            ...TextGradient,
                            width: isMobile ? '100%' : '40%'
                        }}
                    >WHAT WE DELIVER</Typography>
                    <img src={Images.slogan} style={{ width: isMobile ? '100%' : '60%', height: 'auto', objectFit: 'contain' }} alt="" />
                </Stack>
                <Grid container spacing={3}>
                    {
                        features.map((item, index) => (
                            <Grid item md={4} xs={index === 2 ? 12 : isMobile && !isTablet ? 12 : 6} key={index}>
                                <Stack direction={"column"} gap={2} width={'100%'}>
                                    <Typography variant="h5" sx={{ ...TextGradient, fontWeight: '500' }}>{item.title}</Typography>
                                    <Typography variant="body1" color="#000" sx={{ fontWeight: '500', textAlign: 'justify' }}>{item.describe}</Typography>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>
        </div>
    )
}