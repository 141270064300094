import { Icons } from "../../assets/icons";
import { Images } from "../../assets/images";

export const sliderImages = [

  Images.persibLive,
  Images.sosmedSosmed,
  Images.liveLive,
];

export const features = [
  {
    title: "Livestream",
    describe:
      "We've been hosting some crazy cool live streams and live shopping events! Imagine, we've been on air for over 10,000 hours! With our slick multistream and multicam setup, We've also partnered with top brands like Persib, Kojie San, Realfood, and many more.",
    },
  {
    title: "Content Creation",
    describe:
      "We are a never-ending creativity machine! Imagine, every year we create over 1000 creative works. Join us Now ! Let's transform your brand into an enchanting story with our imaginative content!",
  },
  {
    title: "KOL Marketing",
    describe:
      "Let's elevate your brand with our KOL Marketing services! This isn't just regular marketing; it's an advanced digital strategy using influential people to make your product a star. Join our cool clients like Base, Realfood, Scarlett, Elora, and many more",
  },
];

export const sponsors = [
  Icons.jmw,
  Icons.buzz,
  Icons.copper,
  Icons.realfood,
  Icons.birhagia,
  Icons.cityStream,
];