import { Button, Icon, Stack, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Icons } from '../../assets/icons'
import { Colors } from '../../utils/colors'
import { CENTER } from '../../utils/styles'
import { Link } from 'react-router-dom'

export const SloganCard = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <div style={{ width: isMobile ? '90%' : '80%', backgroundColor: Colors.primary, ...CENTER, borderRadius: 20 }}>
                <Stack gap={isMobile ? 3 : 0} direction={'column'} paddingY={isMobile ? 5 : 10} width={isMobile && isTablet ? '90%' : '80%'} alignItems={'flex-start'} sx={{ position: 'relative' }}>
                    <Stack gap={isMobile ? 3 : 0} direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'}>
                        <Stack direction={isMobile && !isTablet ? 'column' : 'row'} alignItems={isMobile && !isTablet ? 'flex-start' : 'center'} gap={3} width={isMobile ? '100%' : '75%'}>
                            <img src={Icons.logoWhite} style={{ width: isMobile && !isTablet ? '30%' : '10%', height: 'auto', objectFit: 'contain' }} alt="" />
                            <Typography variant={isMobile && isTablet ? "h4" : "h3"} color="#000" fontWeight={'700'}><span style={{ color: '#fff' }}>Creative Energy</span> for Everybody.</Typography>
                        </Stack>
                        <Typography variant="body1" color="#fff" sx={{ width: isMobile ? '100%' : '20%' }}>and let us craft a unique brand identity that resonates with your audience.</Typography>
                    </Stack>
                    {isMobile ? null :
                        <>
                            <Toolbar />
                            <Toolbar />
                        </>
                    }
                    <Link to='https://www.instagram.com/rabitcreative/' target='_blank'>
                    <Button
                        variant={"outlined"}
                        color={"warning"}
                        sx={{ borderColor: '#fff', borderRadius: 50, color: '#fff', paddingX: 3, paddingY: 2 }}
                        endIcon={<Icon fontSize={'small'} sx={{ color: '#fff' }}>arrow_right</Icon>}
                    ><span style={{ textAlign: 'left' }}>Start Your Creative Journey Now!</span></Button></Link>
                    <Icon sx={{ fontSize: isMobile ? 50 : 60, color: '#fff', position: 'absolute', bottom: isMobile && !isTablet ? '85%' : 50, right: 0 }}>auto_awesome</Icon>
                </Stack>
            </div>
        </div>
    )
}