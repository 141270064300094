import { Stack, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';
import { Header } from '../../components/services/header';
import { Features } from '../../components/services/contentCreation/features';
import { Milestone } from '../../components/services/contentCreation/milestone';
import { Colors } from '../../utils/colors';
import { Icons } from '../../assets/icons';
import { Packages } from '../../components/services/contentCreation/packages';
import { Schedules } from '../../components/services/contentCreation/schedules';
import { Quota } from '../../components/services/contentCreation/quota';

const ServicesContentCreatorPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={3} />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Header
                    title='Content Creation'
                    desc="Content Creation! We create over 1000 creative pieces every year. Join us! Just like our clients Kencana Energy, Common Grounds, City Storage, City Stream, BUZZ Indonesia, and many more. Let's transform your brand into a captivating story through our imaginative content! 🚀"
                />
                <Features />
                <Milestone />
                <Stack
                    direction={'row'}
                    paddingY={1}
                    alignItems={'center'}
                    justifyContent={"center"}
                    gap={5}
                    width={'120%'}
                    sx={{ backgroundColor: Colors.primary, transform: 'rotate(-3deg) translate(-10%)' }}
                >
                    <Typography variant="h4" color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant="h4" color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant="h4" color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant="h4" color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                </Stack>
                <Toolbar />
                <Packages />
                <Schedules />
                <Quota />
                <Testimonials />
                <SloganCard />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(ServicesContentCreatorPage);