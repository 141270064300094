import * as React from 'react';
import { Icon, IconButton, Stack, Typography } from "@mui/material"
import { CENTER } from "../../utils/styles"
import { Colors } from '../../utils/colors';
import { isMobile, isTablet } from 'react-device-detect';

export const LiveCounts = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} width={isMobile ? '90%' : '80%'} justifyContent={'space-between'} gap={isMobile ? 3 : 0}>
                <Stack direction={'column'} gap={1}>
                    <Typography variant={isMobile ? "h2" : "h1"} color="#000" fontWeight={'800'}>10.000<sup style={{ color: Colors.primary }}>+</sup></Typography>
                    <Typography variant="body1" color="#000" fontWeight={'800'}>Livestream Hours Experience</Typography>
                </Stack>
                <Stack direction={'column'} gap={1}>
                    <Typography variant={isMobile ? "h2" : "h1"} color="#000" fontWeight={'800'}>5.000<sup style={{ color: '#2b4d68' }}>+</sup></Typography>
                    <Typography variant="body1" color="#000" fontWeight={'800'}>KOL Join Our Campaign</Typography>
                </Stack>
                <Stack direction={'column'} gap={1}>
                    <Typography variant={isMobile ? "h2" : "h1"} color="#000" fontWeight={'800'}>1.000<sup style={{ color: '#b18149' }}>+</sup></Typography>
                    <Typography variant="body1" color="#000" fontWeight={'800'}>Output Content</Typography>
                </Stack>
                {isMobile && !isTablet ? null :
                    < IconButton style={{ backgroundColor: '#000', padding: 20 }}>
                        <Icon sx={{ color: '#fff', fontSize: '50px' }}>menu</Icon>
                    </IconButton>
                }
            </Stack>
        </div >
    )
}

export const LiveCountsTab = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={'column'} width={'90%'} gap={3}>
                <Stack direction={'row'} alignItems={'flex-start'} width={'100%'} justifyContent={'space-between'} gap={3}>
                    <Stack direction={'column'} gap={1} width={"50%"}>
                        <Typography variant={"h3"} color="#000" fontWeight={'800'}>10.000<sup style={{ color: Colors.primary }}>+</sup></Typography>
                        <Typography variant="body1" color="#000" fontWeight={'800'}>Livestream Hours Experience</Typography>
                    </Stack>
                    <Stack direction={'column'} gap={1} width={"50%"}>
                        <Typography variant={"h3"} color="#000" fontWeight={'800'}>5.000<sup style={{ color: '#2b4d68' }}>+</sup></Typography>
                        <Typography variant="body1" color="#000" fontWeight={'800'}>KOL Join Our Campaign</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} width={'100%'} justifyContent={'space-between'} gap={3}>
                    <Stack direction={'column'} gap={1} width={"50%"}>
                        <Typography variant={"h3"} color="#000" fontWeight={'800'}>1.000<sup style={{ color: '#b18149' }}>+</sup></Typography>
                        <Typography variant="body1" color="#000" fontWeight={'800'}>Output Content</Typography>
                    </Stack>
                    <div style={{ width: '50%' }}>
                        <IconButton style={{ backgroundColor: '#000', padding: 20 }}>
                            <Icon sx={{ color: '#fff', fontSize: '50px' }}>menu</Icon>
                        </IconButton>
                    </div>
                </Stack>
            </Stack>
        </div >
    )
}