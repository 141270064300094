import { Paper, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Images } from '../../../assets/images';
import { Colors } from '../../../utils/colors';
import { CENTER } from '../../../utils/styles';

export const Milestone = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction="column" width={isMobile ? '90%' : '60%'} gap={3}>
                <div style={{ width: '100%', height: 3, background: 'repeating-linear-gradient(90deg, #fff 0 15px,#000 0 50px)' }}></div>
                <Stack direction={isMobile && !isTablet ? "column" : "row"} gap={3} width={"100%"} alignItems={"stretch"}>
                    <div style={{ width: isTablet && isMobile ? '40%' : isMobile ? '100%' : '50%', height: 'auto', borderRadius: 20, display: 'flex', alignSelf: 'stretch' }}>
                        <img src={Images.homeBg3} style={{ width: '100%', height: '100%', borderRadius: 'inherit', objectFit: 'cover' }} alt="" />
                    </div>
                    <Paper elevation={3} sx={{ backgroundColor: '#f0f0f0', borderRadius: 3, width: isTablet && isMobile ? '60%' : isMobile ? '100%' : '50%', ...CENTER }}>
                        <Stack direction={'column'} gap={3} sx={{ width: '100%' }} paddingX={isMobile ? 3 : 5} paddingY={isMobile ? 5 : 0}>
                            <Typography variant={isTablet && isMobile ? "h4" : "h3"} color={"#000"} fontWeight={"600"}>OUR MILESTONE</Typography>
                            <Stack direction={'column'} gap={1}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} sx={{ color: Colors.primary }} fontWeight={"600"}>Content Total:</Typography>
                                <Typography variant={isTablet && isMobile ? "h5" : "h4"} color={'#000'} fontWeight={"600"}>1000++</Typography>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} color={'#000'}>Output contents in a year.</Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
        </div>
    )
}