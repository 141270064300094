import { Icons } from "../../assets/icons";
import { Images } from "../../assets/images";
export const sponsors = [
  {
    title: "LIVE STREAMING",
    data: [
      Images.liveCopper,
      Images.liveHanum,
      Images.liveHerbana,
      Images.liveKojie,
      Images.Lae,
      Images.PersibFix,
      Images.Precious,
      Images.liveRealfood,
      Images.liveRealfoodSporte
    ],
  },
  {
    title: "CONTENT CREATION",
    data: [
      Images.portoBuzz,
      Images.portoBeringin,
      Images.portoKiloalta,
      Images.portoCommon,
      Images.portoCity,
      Images.portoCityStorage,
      Images.Lae,
      Images.PersibFix,
      Images.Precious,
    ],
  },
  {
    title: "SOCIAL MEDIA MANAGEMENT",
    data: [
      Images.sosmedBirhagia,
      Images.sosmedOurganica,
      Images.sosmedJana,
      Images.sosmedBeringin,
      Images.sosmedKencana,
      Images.portoBuzz,
      Images.portoCity,
      Images.portoKiloalta,
      Images.portoCityStorage,
    ],
  },
];
