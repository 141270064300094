import { Avatar, Icon, Stack, Toolbar, Typography } from "@mui/material"
import { Clients, Icons } from "../../assets/icons"
import { CENTER } from "../../utils/styles"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay, FreeMode } from 'swiper/modules';
import { Colors } from "../../utils/colors";
import { Images } from "../../assets/images";
import { SwiperButtonNext, SwiperButtonPrev } from "../navigation/swiperNavigation";
import { isMobile, isTablet } from "react-device-detect";

export const Testimonials = (props: { page?: string }) => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={'column'} width={'100%'}>
                <Stack direction={'row'} paddingY={1} alignItems={'center'} justifyContent={"center"} gap={isMobile ? 3 : 5} width={'100%'} sx={{ backgroundColor: Colors.primary }}>
                    <Typography variant={isMobile ? 'h5' : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? 'h5' : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? 'h5' : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? 'h5' : "h4"} color="#000" fontWeight={'999'}> RABITCREATIVE</Typography>
                </Stack >
                {/* Testimonials */}
                {/* <div style={{ width: '100%', ...CENTER, backgroundColor: '#252525', padding: '5% 0', position: 'relative' }}>
                    <Stack direction={'column'} gap={3} width={isMobile ? '90%' : '80%'}>
                        <Stack direction={'row'} gap={isMobile ? 2 : 4} alignItems={'center'}>
                            <img src={Icons.message} style={{ height: isMobile ? 30 : 80, width: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                            <Typography variant={isMobile ? "h4" : "h1"} color="#fff" fontWeight={'600'}>TESTIMONIALS</Typography>
                        </Stack>
                        <Swiper
                            slidesPerView={isMobile ? 1 : 2}
                            spaceBetween={isMobile ? 10 : 20}
                            navigation={false}
                            modules={[Navigation, Autoplay]}
                            style={{ width: '100%', padding: isMobile ? '0 10px' : '0 5%' }}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: true
                            }}
                            loop={true}
                        >
                            {
                                [...Array(10)].map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div style={{ border: '1px solid #fff', borderRadius: 20, position: 'relative' }}>
                                            <Stack padding={isMobile ? 3 : 5} direction={'column'} gap={3}>
                                                <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 1 : 2.5} alignItems={isMobile ? 'flex-start' : 'center'} sx={{ postition: 'relative' }}>
                                                    <div style={{ position: 'relative' }}>
                                                        <div style={{ height: 60, width: 60, border: '1px solid #fff', backgroundColor: '#252525', borderRadius: 50, position: 'relative' }}></div>
                                                        <Avatar sx={{ height: 60, width: 60, position: 'absolute', zIndex: 2, top: 0, left: '50%' }} src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-cartoon-man-avatar-vector-ilustration-png-image_6111064.png'}></Avatar>
                                                    </div>
                                                    <div></div>
                                                    <Stack direction={'column'} gap={0}>
                                                        <Typography variant="h6" color="#fff" fontWeight={'600'}>LANA</Typography>
                                                        <Typography variant="body2" color="#fff" fontWeight={'400'}>CUSTOMER</Typography>
                                                    </Stack>
                                                    <Stack alignItems={'center'} direction={'row'} gap={0}>
                                                        {[...Array(5)].map((item, index) => (
                                                            <Icon key={index} fontSize={'small'} sx={{ color: Colors.primary, WebkitTextStroke: '0.5px #fff' }}>star</Icon>
                                                        ))}
                                                    </Stack>
                                                </Stack>
                                                <Typography variant="body1" color="#fff" fontWeight={'400'}>"GERAFIX transformed our brand with ingenious designs. Exceeded expectations. Exceptional!"</Typography>
                                            </Stack>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                            <div style={{ position: 'absolute', left: '0', top: '40%', zIndex: 2 }}>
                                <SwiperButtonPrev />
                            </div>
                            <div style={{ position: 'absolute', right: '0', top: '40%', zIndex: 2 }}>
                                <SwiperButtonNext />
                            </div>
                        </Swiper>
                    </Stack>
                </div> */}
                {
                    props.page === 'portofolio' ? null :
                        <div style={{ width: '100%', ...CENTER, backgroundColor: '#f0f0f0' }}>
                            <Stack direction={'column'} gap={5} width={isMobile ? '90%' : '80%'}>
                                <Toolbar />
                                <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 3 : 0} alignItems={isMobile && isTablet ? 'flex-start' : "center"} justifyContent={'center'}>
                                    <Stack direction={isMobile && !isTablet ? 'column' : 'row'} alignItems="flex-start" justifyContent={'center'} gap={isMobile ? 3 : 5} width={isMobile ? '100%' : '55%'}>
                                        <Typography variant={isMobile && isTablet ? 'h4' : "h3"} color="#000" whiteSpace={'pre-line'}>{isMobile ? 'HAPPY CLIENT' : 'HAPPY CLIENT'}</Typography>
                                        {/* <Typography variant={isMobile && isTablet ? 'body2' : "body1"} color="#000" sx={{ textAlign: 'justify'}}>"We are an ecosystem of creative talent that specializes in key services to provide creative and sales solution for your brand or campaign needs. With over 10,000++ hours of live streaming experience, we have generated Rp 5,000,000,000++ in revenue through livestream broadcasts within 3 months. Social media management 1000++ Output contents in a year. 3000++ KOL Affiliator join our TikTok Affiliate Program  for Realfood, Elora and Skindoze and many more."</Typography> */}
                                    </Stack>
                                    {/* <img src={Images.buttonContact} style={{ width: isMobile && isTablet ? '50%' : isMobile ? '100%' : '30%', height: 'auto', objectFit: 'contain' }} alt="" /> */}
                                </Stack>
                                <Swiper
                                    slidesPerView={isTablet && isMobile ? 3.5 : isMobile ? 1.8 : 6.5}
                                    spaceBetween={15}
                                    freeMode={true}
                                    modules={[FreeMode]}
                                    style={{ width: '100%' }}
                                >
                                    {Clients.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={item} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" key={index} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div></div>
                            </Stack>
                        </div>
                }
            </Stack >
        </div >
    )
}