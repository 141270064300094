import React from "react";
import "./float-btn.css";

export default function FloatBtn() {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      ></link>
      <div className="floating_btn" style={{ gap: 8 }}>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=6282249684362&text&type=phone_number&app_absent=0"
        >
          <div className="contact_icon">
            <i className="fa fa-whatsapp my-float"></i>
          </div>
        </a>
        <div
          style={{
            backgroundColor: "#42db87",
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 3,
            paddingBottom: 3,
            borderRadius: 3,
          }}
        >
          <p className="text_icon" style={{ margin: 0, color: "white" }}>
            Contact Us
          </p>
        </div>
      </div>
    </div>
  );
}
