export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const BoxShadow = {
  boxShadow: "-1px 1px 30px 4px rgba(0,0,0,0.05)",
};

export const TextGradient = {
  background: "linear-gradient(to right, #060401 0%, #F5A725 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};
