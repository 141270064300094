import { Stack, Toolbar } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Header } from '../../components/contact/header';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';
import { Content } from '../../components/contact/content';
import { CENTER } from '../../utils/styles';
import { isMobile } from 'react-device-detect';

const ContactUsPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={5} />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Header />
                <Content />
                <div style={{ width: '100%', ...CENTER }}>
                    <div style={{ width: isMobile ? '90%' : '80%', backgroundColor: '#fff', borderRadius: 10 }}>
                        {/* <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15860.337446738997!2d106.94398511637989!3d-6.383112516438915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6994a643e0d8c3%3A0xd68ae14b9cd43aad!2sKw.%20Niaga%20Citra%20Grand%2C%20RT.002%2FRW.008%2C%20Jatisampurna%2C%20Kec.%20Jatisampurna%2C%20Kota%20Bks%2C%20Jawa%20Barat%2017435!5e0!3m2!1sid!2sid!4v1704966008602!5m2!1sid!2sid"
                            style={{ borderRadius: 10, width: '100%', height: '100%', borderWidth: 0, aspectRatio: isMobile ? 1 : 20 / 9 }}
                            allowFullScreen={true}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title='Address'
                        ></iframe> */}
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5481129881355!2d106.76282067499015!3d-6.191166793796442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f720f06affcb%3A0xa5aa0df8d6180bde!2sCity%20Stream%20Indonesia!5e0!3m2!1sid!2sid!4v1707120132278!5m2!1sid!2sid" 
                        style={{ borderRadius: 10, width: '100%', height: '100%', borderWidth: 0, aspectRatio: isMobile ? 1 : 20 / 9 }}
                        allowFullScreen={true}
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade"
                        title='Address'></iframe>
                    </div>
                </div>
                <Testimonials />
                <SloganCard />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(ContactUsPage);