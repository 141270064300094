import { Images } from "../../assets/images";

export const servicesLive = [
  {
    text: "Livestream Crew Operators, Host, PIC, Dll.",
    color: "#19191A",
  },
  {
    text: "Livestream Operations Studio, Property, Equipment, dll.",
    color: "#E6E6E6",
  },
  {
    text: "Visual Assets Bumper, Sticker, dan Preheat Video.",
    color: "#19191A",
  },
  {
    text: "Multistream 2-3 Platform at the same time.",
    color: "#FFC000",
  },
  {
    text: "HD Quality and Internet High Speed.",
    color: "#CDD4EA",
  },
  {
    text: "Livestream Creative Concept.",
    color: "#E6E6E6",
  },
];

export const packagesLive = [
  Images.livePackage1,
  Images.livePackage2,
  Images.livePackage3,
  Images.livePackage4,
];

export const packagesSocial = [
  Images.socialPackage1,
  Images.socialPackage2,
  Images.socialPackage3,
  Images.socialPackage4,
];

export const packagesContent = [
  Images.contentPackage1,
  Images.contentPackage2,
  Images.contentPackage3,
  Images.contentPackage4,
];

export const marketingList = [
  {
    img: Images.kolImg1,
    title: "REALFOOD",
    desc: "6 kampanye dan masih berjalan, dengan jumlah KOL yang bergabung sebanyak 3000 di Tiktok dan Penjualan meningkat 5x lipat revenue brand di e-commerce.",
  },
  {
    img: Images.kolImg2,
    title: "SKINDOZE",
    desc: "Berfokus pada kampanye awareness brand skincare dari Realfood, dengan jumlah KOL yang bergabung sebanyak 2000.",
  },
  {
    img: Images.kolImg3,
    title: "HANUM",
    desc: "Berfokus pada kampanye awareness brand dan produk terbarunya, dengan jumlah KOL yang bergabung sebanyak 400 di Tiktok dan 50 di Instagram.",
  },
  {
    img: Images.kolImg4,
    title: "BASE",
    desc: "Berfokus pada hasil konten KOL yang berkualitas dan meningkatkan brand image, dengan jumlah KOL yang bergabung sebanyak 50 di Instagram dan Tiktok.",
  },
];

export const socialFeatures = [
  {
    text: "Admin and Audience Engagement.",
    color: "#FFC000",
  },
  {
    text: "Content Scheduling",
    color: "#CDD4EA",
  },
  {
    text: "Landing Page",
    color: "#E6E6E6",
  },
  {
    text: "Profile Optimization",
    color: "#19191A",
  },
  {
    text: "Analytics and Insight",
    color: "#E6E6E6",
  },
  {
    text: "Reporting",
    color: "#19191A",
  },
];

export const contentFeatures = [
  {
    text: "Admin and Audience Engagement.",
    color: "#FFC000",
  },
  {
    text: "Content Scheduling",
    color: "#CDD4EA",
  },
  {
    text: "Profile Optimization",
    color: "#19191A",
  },
  {
    text: "Analytics and Insight",
    color: "#E6E6E6",
  },
  {
    text: "Reporting",
    color: "#19191A",
  },
];

export const quotaList = [
  {
    color: "#ffbf00",
    text: "10 Poin Quota",
  },
  {
    color: "#cdd4ea",
    text: "Xbanner",
  },
  {
    color: "#cdd4ea",
    text: "Poster",
  },
  {
    color: "#ffbf00",
    text: "Name Card",
  },
  {
    color: "#ffbf00",
    text: "Flyer",
  },
  {
    color: "#cdd4ea",
    text: "And Many More",
  },
];
