import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Colors } from '../../../utils/colors';
import { CENTER, TextGradient } from '../../../utils/styles';

export const Features = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={'column'} gap={3} alignItems={'center'} width={isMobile ? '90%' : '80%'}>
                <Paper elevation={3} sx={{ ...CENTER, paddingY: 1, backgroundColor: '#cdd4ea', borderRadius: 9, width: isMobile ? '80%' : '30%' }}>
                    <Typography variant="h5" fontWeight={'600'} sx={{ ...TextGradient }}>Our KOL Categories</Typography>
                </Paper>
                <Paper elevation={3} sx={{ ...CENTER, backgroundColor: '#f0f0f0', borderRadius: 3, width: '100%' }}>
                    <Grid container spacing={2} padding={isMobile ? 2 : 5}>
                        <Grid item md={4} xs={isTablet && isMobile ? 6 : 12}>
                            <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: Colors.primary, borderRadius: 3, width: '100%', height: '100%' }}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color="#000">Tiktok Affiliate Program</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={isTablet && isMobile ? 6 : 12}>
                            <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: Colors.primary, borderRadius: 3, width: '100%', height: '100%' }}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color="#000">Brand Ambassador</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: Colors.primary, borderRadius: 3, width: '100%', height: '100%' }}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color="#000">Instagram Endorsement</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={3} sx={{ ...CENTER, paddingY: 1, backgroundColor: '#cdd4ea', borderRadius: 9, width: isMobile ? '80%' : '30%' }}>
                    <Typography variant="h5" fontWeight={'600'} sx={{ ...TextGradient }}>Our Features</Typography>
                </Paper>
                <Paper elevation={3} sx={{ ...CENTER, backgroundColor: '#f0f0f0', borderRadius: 3, width: '100%' }}>
                    <Stack direction={'column'} gap={3} width={'100%'} padding={isMobile ? 2 : 5}>
                        <Stack direction={isMobile && !isTablet ? "column" : "row"} alignItems="strecth" justifyContent={'space-between'} gap={3} width={'100%'}>
                            <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: '#cdd4ea', borderRadius: 3, width: isMobile ? '100%' : '50%' }}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color="#000">Campaign and strategy plan</Typography>
                            </Box>
                            <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: '#cdd4ea', borderRadius: 3, width: isMobile ? '100%' : '50%' }}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color="#000">Targeted category KOL</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={isMobile && !isTablet ? "column" : "row"} alignItems="strecth" justifyContent={'space-between'} gap={3} width={'100%'}>
                            <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: '#cdd4ea', borderRadius: 3, width: isMobile ? '100%' : '50%' }}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color="#000">Performance tracking</Typography>
                            </Box>
                            <Box sx={{ ...CENTER, paddingX: 3, paddingY: 2, backgroundColor: '#cdd4ea', borderRadius: 3, width: isMobile ? '100%' : '50%' }}>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} textAlign={'center'} color="#000">Creative brief</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Paper>
            </Stack>
        </div>
    )
}