import React from 'react'
import { Box, Stack, Typography } from "@mui/material"
import { Images } from "../../assets/images"
import { CENTER, TextGradient } from "../../utils/styles"
import { sliderImages } from "./data"
import { isMobile, isTablet } from 'react-device-detect'

export const ContentGrid = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={isMobile ? "column" : "row"} width={isMobile ? '90%' : '80%'} gap={3} alignItems={'stretch'} justifyContent='space-between'>
                <Stack direction="column" width={isMobile ? '100%' : '70%'} alignItems={'center'} gap={3} justifyContent='space-between'>
                    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                        <img src={sliderImages[0]} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 15, aspectRatio: isMobile && !isTablet ? 1 : 16 / 9, position: 'relative' }} alt="" />
                        <img src={Images.vector3D} style={{ position: 'absolute', left: '-5%', objectFit: 'cover', bottom: 0, height: isMobile ? '60%' : '100%' }} alt="" />
                    </Box>
                    <Stack direction={isMobile && !isTablet ? 'column' : "row"} justifyContent={'space-between'} alignItems={'center'} gap={3} width={'100%'}>
                        <div style={{ width: isMobile && !isTablet ? '100%' : '50%', display: 'flex', alignItems: 'flex-end' }}>
                            <img src={sliderImages[1]} style={{ width: '100%', aspectRatio: 1, objectFit: 'cover', borderRadius: 15 }} alt="" />
                        </div>
                        <div style={{ width: isMobile && !isTablet ? '100%' : '50%', display: 'flex', alignItems: 'flex-end' }}>
                            <img src={sliderImages[2]} style={{ width: '100%', aspectRatio: 1, objectFit: 'cover', borderRadius: 15 }} alt="" />
                        </div>
                    </Stack>
                </Stack>
                <Stack direction={"column"} gap={3} width={isMobile ? '100%' : '30%'} alignItems={"flex-start"}>
                    <Typography variant="h2" sx={{ ...TextGradient }}>SELECTED WORK</Typography>
                    {/* <Typography variant="body1" sx={{ color: '#000', fontWeight: '600', textAlign: 'justify' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.</Typography> */}
                    <video src={require('../../assets/video-example.mp4')} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 15 }} muted autoPlay></video>
                </Stack>
            </Stack>
        </div>
    )
}