import { Box, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Colors } from '../../../utils/colors';
import { CENTER } from '../../../utils/styles';
import { servicesLive } from '../data';

export const Services = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={"column"} gap={3} width={isMobile ? "90%" : "80%"}>
                <Typography variant="h6" sx={{ color: Colors.primary }}>Our Services</Typography>
                {/* <Stack direction={isMobile && !isTablet ? 'column' : "row"} alignItems={'stretch'} justifyContent={'space-between'} gap={isMobile ? 3 : 0}>
                    <Stack direction={'column'} gap={1} width={isMobile && !isTablet ? '100%' : "48%"}>
                        <Typography variant="h6" fontWeight={'600'} color="#000">Live Stream Services</Typography>
                        <Typography variant="body1" color="#000" sx={{ textAlign: 'justify'}}>With over 10,000++ hours of live streaming experience, we have generated Rp 5,000,000,000++ in revenue through livestream broadcasts within 3 months.</Typography>
                    </Stack>
                    {isMobile && !isTablet ?
                        <div style={{ height: 1, width: '100%', backgroundColor: '#000' }}></div>
                        :
                        <div style={{ height: 'auto', width: 2, backgroundColor: '#000' }}></div>
                    }
                    <Stack direction={'column'} gap={1} width={isMobile && !isTablet ? '100%' : "48%"}>
                        <Typography variant="h6" fontWeight={'600'} color="#000">Our Mission</Typography>
                        <Typography variant="body1" color="#000" sx={{ textAlign: 'justify' }}>Through our in-house studio with skilled live streaming operators and professional equipment are dedicated to providing you with a professionally executed, high-quality live stream. This creative concept not only upholds the integrity of your brand image but also serves as a catalyst for revenue growth.</Typography>
                    </Stack>
                </Stack> */}
                <br />
                <Grid container spacing={2}>
                    {
                        servicesLive.map((item, index) => (
                            <Grid item md={4} xs={isTablet && isMobile ? index === 2 || index === 5 ? 12 : 6 : 12} key={index}>
                                <Box sx={{ height: '100%', backgroundColor: item.color, borderRadius: 3, ...CENTER, padding: 3 }}>
                                    <Typography variant={isTablet && isMobile ? "body1" : "h6"} color={item.color === '#19191A' ? '#fff' : '#000'}>{item.text}</Typography>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>
        </div>
    )
}