import { Icon, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { Images } from '../../../assets/images';
import { CENTER, TextGradient } from '../../../utils/styles';

export const Content = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={isMobile ? 'column-reverse' : 'row'} gap={3} width={isMobile ? '90%' : '80%'} alignItems={'stretch'} justifyContent="space-between">
                <Stack width={isMobile ? '100%' : '50%'} direction={"column"} gap={3} height={'auto'} justifyContent='space-between'>
                    <div style={{ width: '100%', borderRadius: 15, height: '40%' }}>
                        <img src={Images.aboutBg1} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} alt="" />
                    </div>
                    <div style={{ width: '100%', borderRadius: 15, height: '20%' }}>
                        <img src={Images.homeBg1} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} alt="" />
                    </div>
                    <div style={{ width: '100%', borderRadius: 15, height: '40%' }}>
                        <img src={Images.aboutBg2} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} alt="" />
                    </div>
                </Stack>
                <Stack width={isMobile ? '100%' : '50%'} direction={'column'} gap={3} height={'auto'} justifyContent={'space-between'}>
                    <Stack direction={'column'} width={'100%'} height={'20%'} justifyContent={'space-between'}>
                        <Typography variant={isMobile ? "h3" : "h2"} sx={{ ...TextGradient }} fontWeight={'800'} whiteSpace={'pre-line'}>OUR{'\n'}PORTFOLIO</Typography>
                        <div>
                            <Icon fontSize={"large"} sx={{ color: '#000' }}>more_horiz</Icon>
                            <Typography variant="h6" color="#000" textTransform={'uppercase'}>Copper Indonesia, Realfood, herbana, kojie san, mykonos.</Typography>
                        </div>
                    </Stack>
                    <div style={{ width: '100%', borderRadius: 15, height: '80%' }}>
                        <img src={Images.aboutBg3} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} alt="" />
                    </div>
                </Stack>
            </Stack>
        </div>
    )
}