import React from "react";
import TitlePage from "./routerTitle";
import { Routes, Route, useLocation } from "react-router-dom";

import HomePage from "../pages/home/home";
import AboutUsPage from "../pages/about/aboutUs";
import ContactUsPage from "../pages/contact/contact";
import PortofolioPage from "../pages/portofolio/portofolio";
import ServicesLiveStreamPage from "../pages/services/liveStream";
import ServicesInfluencerPage from "../pages/services/influencer";
import ServicesSocialMediaPage from "../pages/services/socialMedia";
import ServicesContentCreatorPage from "../pages/services/contentCreator";
import { AnimatePresence } from "framer-motion";
import MRKPortofolio from "../pages/MRK/MRKPortofolio";

const PageRoutes = () => {
    const location = useLocation()
    const { pathname } = useLocation();

    React.useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)
    }, [pathname]);

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route index element={<TitlePage title="Home | Rabitcreative."><HomePage /></TitlePage>} />
                <Route path="/about-us" element={<TitlePage title="About Us | Rabitcreative."><AboutUsPage /></TitlePage>} />
                <Route path="/portofolio" element={<TitlePage title="Portofolio | Rabitcreative."><PortofolioPage /></TitlePage>} />
                <Route path="/contact" element={<TitlePage title="Contact Us | Rabitcreative."><ContactUsPage /></TitlePage>} />
                <Route path="/service/live-stream" element={<TitlePage title="Live Stream | Rabitcreative."><ServicesLiveStreamPage /></TitlePage>} />
                <Route path="/service/kol-influencer-marketing" element={<TitlePage title="KOL and Influencer Marketing | Rabitcreative."><ServicesInfluencerPage /></TitlePage>} />
                <Route path="/service/social-media-management" element={<TitlePage title="Social Media Management | Rabitcreative."><ServicesSocialMediaPage /></TitlePage>} />
                <Route path="/service/content-creation" element={<TitlePage title="Content Creation | Rabitcreative."><ServicesContentCreatorPage /></TitlePage>} />
                <Route path="/mrk-portofolio" element={<TitlePage title="MRK Portofolio | Rabitcreative."><MRKPortofolio /></TitlePage>} />
            </Routes>
        </AnimatePresence>
    )
}

export default PageRoutes;