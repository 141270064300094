import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { Images } from '../../assets/images';
import { CENTER } from '../../utils/styles';
import { sliderImages } from './data';

export const SliderImages = () => {
    return (
        <div style={{ width: '100%', ...CENTER, margin: 0, padding: 0, boxSizing: 'border-box' }}>
            <Stack direction={'row'} gap={4} width={'80%'} alignItems={'center'} justifyContent={'center'}>
                {
                    sliderImages.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                flex: '1 0 120px',
                                borderRadius: '15px',
                                transition: '0.5s ease-in-out',
                                cursor: 'pointer',
                                position: 'relative',
                                overflow: 'hidden',
                                '&:hover': {
                                    flex: '3 0 250px',
                                    opacity: 1,
                                    transform: 'translatey(0%)',
                                    visibility: 'visible',
                                }
                            }}
                        >
                            <div
                                style={{
                                    background: `url(${item}) no-repeat 50% / cover`,
                                    width: '100%',
                                    height: '80vh'
                                }}
                            ></div>
                        </Box>
                    ))
                }
            </Stack>
        </div>
    )
}

export const GridImages = () => {
    return (
        <div style={{ width: '100%', ...CENTER, zIndex: 1 }}>
            <Stack direction={'column'} gap={2} width={'90%'}>
                <img src={Images.aboutBg2} style={{ width: '100%', aspectRatio: 1, borderRadius: 15, objectFit: 'cover' }} alt="" />
                <Stack direction={'row'} alignItems={'center'} justifyContent='space-between' gap={2} width="100%">
                    <div style={{ width: '33%' }}>
                        <img src={Images.homeBg2} style={{ width: '100%', aspectRatio: 1, borderRadius: 15, objectFit: 'cover' }} alt="" />
                    </div>
                    <div style={{ width: '33%' }}>
                        <img src={Images.aboutBg3} style={{ width: '100%', aspectRatio: 1, borderRadius: 15, objectFit: 'cover' }} alt="" />
                    </div>
                    <div style={{ width: '33%' }}>
                        <img src={Images.aboutBg1} style={{ width: '100%', aspectRatio: 1, borderRadius: 15, objectFit: 'cover' }} alt="" />
                    </div>
                </Stack>
            </Stack>
        </div>
    )
}