import { Stack, Toolbar } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { CENTER } from '../../utils/styles';
import { Header } from '../../components/home/header';
import { GridImages, SliderImages } from '../../components/home/sliderImages';
import { LiveCounts, LiveCountsTab } from '../../components/home/liveCounts';
import { Features } from '../../components/home/features';
import { ContentGrid } from '../../components/home/contentGrid';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';
import { Footer } from '../../components/navigation/footer';
import { Images } from '../../assets/images';
import { isMobile, isTablet } from 'react-device-detect';

const HomePage = () => {
    return (
        <div style={{
            width: '100%',
            backgroundColor: '#fff',
            position: 'relative',
            overflowX: 'hidden',
            backgroundImage: `url(${Images.background})`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'contain',
        }}>
            <div style={{ width: '100%' }}>
                <NavigationBar active={1} />
            </div>
            <Toolbar />
            <Toolbar />
            {
                isMobile ? null :
                    <div style={{
                        width: '100%',
                        position: 'absolute',
                        zIndex: 0,
                        height: '100vh',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '0 0 40px 40px',
                        top: 0,
                        left: 0,
                        right: 0
                    }}></div>
            }
            <img
                src={Images.vectorFluid}
                style={{
                    width: '20vw',
                    height: 'auto',
                    objectFit: 'contain',
                    position: 'absolute',
                    top: -100, right: -100,
                    zIndex: 0,
                    transform: 'rotate(140deg)'
                }}
                alt=""
            />
            <img
                src={Images.vectorFluid}
                style={{
                    width: '20vw',
                    height: 'auto',
                    objectFit: 'contain',
                    position: 'absolute',
                    top: '8%', left: -100,
                    zIndex: 0,
                    transform: 'rotate(180deg)'
                }}
                alt=""
            />
            <img
                src={Images.vectorFluid}
                style={{
                    width: '20vw',
                    height: 'auto',
                    objectFit: 'contain',
                    position: 'absolute',
                    top: '25%', right: -100,
                    zIndex: 0,
                    transform: 'rotate(0deg)'
                }}
                alt=""
            />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Header />
                {
                    isMobile ?
                        <GridImages />
                        :
                        <SliderImages />
                }
                {isMobile ? null : <div></div>}
                {isMobile && isTablet ?
                    <LiveCountsTab />
                    :
                    <LiveCounts />
                }
                <div style={{ width: '100%', ...CENTER }}>
                    <div style={{ width: isMobile ? '90%' : '80%', backgroundColor: '#000', height: 3 }}></div>
                </div>
                <Features />
                <ContentGrid />
                <Testimonials />
                <SloganCard />
                <Footer />
            </Stack>
            <br />
        </div>
    )
}

export default RouterTransitions(HomePage);