import { Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Images } from '../../../assets/images'
import { CENTER } from '../../../utils/styles'

export const ContentGrid = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction="column">
                <div
                    style={{
                        backgroundImage: `url(${Images.socialBg})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                    }}
                >
                    <div style={{ width: '100%', ...CENTER }}>
                        <Stack direction={isMobile ? 'column' : 'row'} width={isMobile ? '90%' : "80%"} gap={5} alignItems={"center"} paddingY={isMobile ? 3 : 5}>
                            <img src={Images.socialGrid1} style={{ width: isMobile ? '100%' : '70%', objectFit: 'contain', height: 'auto' }} alt="" />
                            <Stack direction={'column'} gap={isTablet && isMobile ? 1 : 2} alignItems={'flex-start'} width={'100%'}>
                                <Typography variant={isTablet && isMobile ? "h6" : "h5"} color="#000" fontWeight={'400'}>SOCIAL MEDIA MANAGEMENT</Typography>
                                <Typography variant={isTablet && isMobile ? "h4" : "h3"} color="#000" fontWeight={'700'}>PT. KENCANA ENERGI TBK</Typography>
                                <Icon sx={{ color: '#000' }} fontSize={"large"}>more_horiz</Icon>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} color="#000" fontWeight={'400'}>PT Kencana Energi Lestari Tbk is a leading provider of renewable energy in Indonesia.</Typography>
                            </Stack>
                        </Stack>
                    </div>
                </div>
                <div
                    style={{
                        backgroundImage: `url(${Images.socialBg})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                    }}
                >
                    <div style={{ width: '100%', ...CENTER }}>
                        <Stack direction={isMobile ? 'column' : 'row'} width={isMobile ? "90%" : "80%"} gap={5} alignItems={"center"} paddingY={isMobile ? 3 : 5}>
                            <img src={Images.socialGrid2} style={{ width: isMobile ? '100%' : '70%', objectFit: 'contain', height: 'auto' }} alt="" />
                            <Stack direction={'column'} gap={isTablet && isMobile ? 1 : 2} alignItems={'flex-start'} width={'100%'}>
                                <Typography variant={isTablet && isMobile ? "h6" : "h5"} color="#000" fontWeight={'400'}>SOCIAL MEDIA MANAGEMENT</Typography>
                                <Typography variant={isTablet && isMobile ? "h4" : "h3"} color="#000" fontWeight={'700'}>BIRHAGIA</Typography>
                                <Icon sx={{ color: '#000' }} fontSize={"large"}>more_horiz</Icon>
                                <Typography variant={isTablet && isMobile ? "body1" : "h6"} color="#000" fontWeight={'400'}>Distributor Craft Beer in surabaya.</Typography>
                            </Stack>
                        </Stack>
                    </div>
                </div>
            </Stack>
        </div>
    )
}