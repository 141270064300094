import { Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Images } from '../../../assets/images'
import { CENTER, TextGradient } from '../../../utils/styles'

export const Milestone = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={isMobile && !isTablet ? 'column' : "row"} width={isMobile ? '90%' : '80%'} gap={isMobile ? 3 : 5} alignItems={'stretch'}>
                <div style={{ width: isTablet && isMobile ? '40%' : isMobile ? '100%' : '30%', height: 'auto', borderRadius: 20, display: 'flex', alignSelf: 'stretch' }}>
                    <img src={Images.aboutBg1} style={{ width: '100%', height: 'auto', borderRadius: 'inherit', objectFit: 'cover' }} alt="" />
                </div>
                <Stack direction={'column'} gap={3} sx={{ width: isTablet && isMobile ? '60%' : isMobile ? '100%' : '70%', height: '100%' }} justifyContent={'space-between'}>
                    <Typography variant={isTablet && isMobile ? 'h4' : "h3"} sx={{ ...TextGradient }} fontWeight={"600"}>OUR MILESTONE</Typography>
                    <Stack direction={'column'} gap={1}>
                        <Typography variant={isTablet && isMobile ? 'body1' : "h6"} sx={{ ...TextGradient }} fontWeight={"600"}>KOL Affiliator</Typography>
                        <Typography variant={isTablet && isMobile ? 'h5' : "h4"} color={'#000'} fontWeight={"600"}>5000++</Typography>
                        <Typography variant={isTablet && isMobile ? 'body1' : "h6"} color={'#000'}>KOL Affiliator join our TikTok Affiliate Program for Realfood, Elora and Skindoze</Typography>
                    </Stack>
                    <Stack direction={'column'} gap={1}>
                        <Typography variant={isTablet && isMobile ? 'body1' : "h6"} sx={{ ...TextGradient }} fontWeight={"600"}>KOL Brand Revenues;</Typography>
                        <Typography variant={isTablet && isMobile ? 'h5' : "h4"} color={'#000'} fontWeight={"600"}>Earn 5x More</Typography>
                        <Typography variant={isTablet && isMobile ? 'body1' : "h6"} color={'#000'}>Our Brand earn revenue 5x more than usual</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}