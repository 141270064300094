import { Stack, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';
import { Footer } from '../../components/navigation/footer';
import { Testimonials } from '../../components/contents/testimonials';
import { SloganCard } from '../../components/contents/sloganCard';
import { Header } from '../../components/services/header';
import { Features } from '../../components/services/socialMedia/features';
import { Milestone } from '../../components/services/socialMedia/milestone';
import { Packages } from '../../components/services/socialMedia/packages';
import { Icons } from '../../assets/icons';
import { Colors } from '../../utils/colors';
import { ContentGrid } from '../../components/services/socialMedia/contentGrid';
import { isMobile, isTablet } from 'react-device-detect';

const ServicesSocialMediaPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff', overflowX: 'hidden' }}>
            <NavigationBar active={3} />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'}>
                <Header
                    title={isMobile && !isTablet ? 'Social Media' : 'Social Media Management'}
                    desc='We help brands to create content, publishing, developing a strategy, interacting with users, growing reach, and monitoring the performance of your brands account on social media.'
                />
                <Features />
                <Milestone />
                <Stack
                    direction={'row'}
                    paddingY={1}
                    alignItems={'center'}
                    justifyContent={"center"}
                    gap={isMobile ? 3 : 5}
                    width={'120%'}
                    sx={{ backgroundColor: Colors.primary, transform: 'rotate(-3deg) translate(-10%)' }}
                >
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                    <img src={Icons.asterisk} style={{ width: 'auto', height: isMobile ? 25 : 30, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    <Typography variant={isMobile ? "h5" : "h4"} color="#000" fontWeight={'999'}>RABITCREATIVE</Typography>
                </Stack>
                {isMobile ? null : <Toolbar />}
                <div style={{ width: '100%' }}>
                    <Packages />
                    {isMobile ? <Toolbar /> : null}
                    <ContentGrid />
                    <Testimonials />
                </div>
                <SloganCard />
                <Footer />
            </Stack>
        </div>
    )
}

export default RouterTransitions(ServicesSocialMediaPage);