import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Colors } from '../../utils/colors'
import { CENTER } from '../../utils/styles'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { SwiperButtonNext, SwiperButtonPrev } from '../navigation/swiperNavigation';
import { sponsors } from './data';
import { isMobile } from 'react-device-detect';

export const PortList = () => {
    const [active, setActive] = React.useState<any>(null)

    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction={"column"} gap={5} width={isMobile ? '90%' : '80%'}>
                {
                    sponsors.map((item, index) => (
                        <Stack direction={'column'} key={index} gap={isMobile ? 0 : 1}>
                            <Typography marginLeft={isMobile ? 0 : '5%'} variant={isMobile ? 'body1' : "h6"} color="#000" fontWeight="400">PORTOFOLIO</Typography>
                            <Typography marginLeft={isMobile ? 0 : '5%'} variant={isMobile ? 'h5' : "h4"} color={Colors.primary} fontWeight="600">{item.title}</Typography>
                            <br />
                            <div
                                onMouseEnter={() => setActive(index)}
                                onMouseLeave={() => setActive(null)}
                                style={{ backgroundColor: '#e6e6e6', borderRadius: 100, width: '100%' }}
                            >
                                <div style={{ width: '100%', padding: '0 5%' }}>
                                    <Swiper
                                        slidesPerView={isMobile ? 3 : 6}
                                        spaceBetween={10}
                                        navigation={false}
                                        modules={[Navigation]}
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            item.data.map((value, idc) => (
                                                <SwiperSlide key={idc}>
                                                    <img src={value} style={{ height: isMobile ? 100 : 150, width: '100%', objectFit: 'contain'}} alt="" />
                                                </SwiperSlide>
                                            ))
                                        }
                                        {active === index ?
                                            <div style={{ position: 'absolute', left: isMobile ? 0 : 20, top: '35%', zIndex: 2 }}>
                                                <SwiperButtonPrev />
                                            </div> : null
                                        }
                                        {active === index ?
                                            <div style={{ position: 'absolute', right: isMobile ? 0 : 20, top: '35%', zIndex: 2 }}>
                                                <SwiperButtonNext />
                                            </div>
                                            : null
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </Stack>
                    ))
                }
            </Stack>
        </div>
    )
}