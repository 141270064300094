import { Button, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Images } from '../../../assets/images'
import { Colors } from '../../../utils/colors'
import { CENTER } from '../../../utils/styles'

export const Schedules = () => {
    return (
        <div style={{ width: '100%', ...CENTER }}>
            <Stack direction="column" gap={5} width={isMobile ? '90%' : '80%'}>
                <Stack direction={isMobile ? 'column-reverse' : 'row'} width={'100%'} gap={5} alignItems='center' sx={{ paddingY: isMobile ? 3 : 5, paddingX: isMobile ? 3 : 10, border: '1px dashed #000', borderRadius: 3 }}>
                    <Stack direction={"column"} gap={2} alignItems={"flex-start"} width={isMobile ? '100%' : '50%'}>
                        <div>
                            <Typography variant="h6" color="#000">CONTENT CREATION</Typography>
                            <Typography variant="h4" color="#000" fontWeight={'700'}>COMMON GROUNDS</Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontWeight={'600'} color="#000">DESC</Typography>
                            <Typography variant="body1" color="#000" sx={{ textAlign: 'justify' }}>Local coffee shop providing premium coffee and delivering the highest quality customer experience while supporting the local community.</Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontWeight={'600'} color="#000">DATE</Typography>
                            <Typography variant="body1" color="#000">2021 - 2023</Typography>
                        </div>
                        <Button
                            variant={"contained"}
                            color="warning"
                            sx={{ backgroundColor: Colors.primary, borderRadius: 9, textTransform: 'none', color: '#000' }}
                            startIcon={<Icon fontSize={"large"} sx={{ color: '#000' }}>smart_display</Icon>}
                        >Click to Portofolio</Button>
                    </Stack>
                    <div style={{ width: isMobile ? '100%' : '50%' }}>
                        <img src={Images.contentImg1} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    </div>
                </Stack>
                <Stack direction={isMobile ? 'column-reverse' : 'row'} width={'100%'} gap={5} alignItems='center' sx={{ paddingY: isMobile ? 3 : 5, paddingX: isMobile ? 3 : 10, border: '1px dashed #000', borderRadius: 3 }}>
                    <Stack direction={"column"} gap={2} alignItems={"flex-start"} width={isMobile ? '100%' : '50%'}>
                        <div>
                            <Typography variant="h6" color="#000">CONTENT CREATION</Typography>
                            <Typography variant="h4" color="#000" fontWeight={'700'}>CITY STORAGE</Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontWeight={'600'} color="#000">DESC</Typography>
                            <Typography variant="body1" color="#000" sx={{ textAlign: 'justify' }}>City storage is more than just a self storage service. City Storage offers a solution to a lifestyle where having more space is now a luxury everyone can afford.</Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontWeight={'600'} color="#000">DATE</Typography>
                            <Typography variant="body1" color="#000">2021 - 2023</Typography>
                        </div>
                        <Button
                            variant={"contained"}
                            color="warning"
                            sx={{ backgroundColor: Colors.primary, borderRadius: 9, textTransform: 'none', color: '#000' }}
                            startIcon={<Icon fontSize={"large"} sx={{ color: '#000' }}>smart_display</Icon>}
                        >Click to Portofolio</Button>
                    </Stack>
                    <div style={{ width: isMobile ? '100%' : '50%' }}>
                        <img src={Images.contentImg2} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                    </div>
                </Stack>
            </Stack>
        </div>
    )
}